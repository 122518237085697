export const counterFloodLight = (sendToId) => {
  console.log(sendToId);

  window.gtag('event', 'conversion', {
    'allow_custom_scripts': true,
    'send_to': `${sendToId}`
  });
};

export const FLOODLIGHT_COUNTER = {
  LimcaSportz_ICC23_ContactUs_Counter: "DC-12665261/limca0/limca01l+unique",

  LimcaSportz_ICC23_EnterCode_Counter: "DC-12665261/limca0/limca01g+unique",

  LimcaSportz_ICC23_FAQs_Counter: "DC-12665261/limca0/limca01i+unique",

  LimcaSportz_ICC23_FitnessTracker_Counter:
    "DC-12665261/limca0/limca01b+unique",

  LimcaSportz_ICC23_GetOTP_Counter: "DC-12665261/limca0/limca018+unique",

  LimcaSportz_ICC23_HomePage_Counter: "DC-12665261/limca0/limca016+unique",

  LimcaSportz_ICC23_HowToPlay_Counter: "DC-12665261/limca0/limca01e+unique",

  LimcaSportz_ICC23_Login_Counter: "DC-12665261/limca0/limca017+unique",

  LimcaSportz_ICC23_MyWinning_Counter: "DC-12665261/limca0/limca01a+unique",

  LimcaSportz_ICC23_OTPVerify_Counter: "DC-12665261/limca0/limca019+unique",

  LimcaSportz_ICC23_ParticipateNow_Counter:
    "DC-12665261/limca0/limca01d+unique",

  LimcaSportz_ICC23_PrivacyPolicy_Counter: "DC-12665261/limca0/limca01j+unique",

  LimcaSportz_ICC23_SignUp_Counter: "DC-12665261/limca0/limca01-+unique",

  LimcaSportz_ICC23_TC_Counter: "DC-12665261/limca0/limca01k+unique",

  LimcaSportz_ICC23_TakeTheTest_Counter: "DC-12665261/limca0/limca01f+unique",

  LimcaSportz_ICC23_YoYoSubmit_Counter: "DC-12665261/limca0/limca01h+unique",

  LimcaSportz_ICC23_YoYoTest_Counter: "DC-12665261/limca0/limca01c+unique",
  //new

  LimcaSportz_ICC23_ClaimNow_Counter:"DC-12665261/limca0/limca01m+unique",

  LimcaSportz_ICC23_Share_Counter:"DC-12665261/limca0/limca01n+unique",

  LimcaSportz_ICC23_DetailClaimNow_Counter:"DC-12665261/limca0/limca01o+unique"


};
