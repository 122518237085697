import React from 'react';
import { Link } from 'react-router-dom';
import AppModal from '../../components/modals';
import { TERMS_AND_CONDITIONS_URL } from '../../utils/constants';
import { FLOODLIGHT_COUNTER, counterFloodLight } from '../../utils/helpers/floodlight-helper';

function FitnessTrackerSocialShare({ show, onClose }) {
  return (
    <div>
      <AppModal show={show} onClose={onClose} bodyClass="max-w-none">
        <img
          className="modal-logo"
          src="/assets/img/common/logo.png"
          alt="Logo"
        />
        <img
          className="mt-8 mb-2"
          src="/assets/img/products/ft-circle.svg"
          alt="Ticket"
        />
        <div className="row justify-content-center">
          <div className="co-12 col-sm-10">
            <h3 className="modal-title modal-title-sm">
              I just won an all-new Fitness Tracker! You can too!
            </h3>
            <p className="mt-4 mb-10">
              Just grab a bottle of Limca® Sportz and get playing! Visit <Link to="https://lmsicc.coke2home.com/">https://lmsicc.coke2home.com/</Link>
            </p>
            <Link className="modal-link" to={TERMS_AND_CONDITIONS_URL} target={"_blank"}
              onClick={() => {
                counterFloodLight(FLOODLIGHT_COUNTER.LimcaSportz_ICC23_TC_Counter);
              }}
            >T&C Apply</Link>
          </div>
        </div>
      </AppModal>
    </div>
  );
}

export default FitnessTrackerSocialShare;
