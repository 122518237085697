import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  FAQ_URL,
  PRIVACY_POLICY_URL,
  TERMS_AND_CONDITIONS_URL,
} from "../../../utils/constants";
import { FLOODLIGHT_COUNTER, counterFloodLight } from "../../../utils/helpers/floodlight-helper";

function OtherLinks({ isLoggedIn, toggleNavbar }) {
  // toggle the contact information
  const [showContactInfo, setShowContactInfo] = useState(false);
  const toggleContactInfo = () => {
    setShowContactInfo(!showContactInfo);
  };

  return (
    <>
      <ul className="main-nav devider">
        <li
          className="main-nav-item"
          onClick={() => {
            counterFloodLight(FLOODLIGHT_COUNTER.LimcaSportz_ICC23_FAQs_Counter);
            toggleNavbar();
          }}
        >
          <Link to={FAQ_URL} target={"_blank"} className="main-nav-link">
            <span>FAQs</span>
          </Link>
        </li>
        <li
          className="main-nav-item"
          onClick={() => {
            counterFloodLight(FLOODLIGHT_COUNTER.LimcaSportz_ICC23_PrivacyPolicy_Counter);
            toggleNavbar();
          }}
        >
          <Link
            to={PRIVACY_POLICY_URL}
            target={"_blank"}
            className="main-nav-link"
          >
            <span>Privacy Policy</span>
          </Link>
        </li>
        <li
          className="main-nav-item"
          onClick={() => {
            counterFloodLight(FLOODLIGHT_COUNTER.LimcaSportz_ICC23_TC_Counter);
            toggleNavbar();
          }}
        >
          <Link
            to={TERMS_AND_CONDITIONS_URL}
            target={"_blank"}
            className="main-nav-link"
          >
            <span>Terms & Conditions</span>
          </Link>
        </li>
        <li
          className="main-nav-item"
          onClick={() => {
            counterFloodLight(FLOODLIGHT_COUNTER.LimcaSportz_ICC23_ContactUs_Counter);
          }}
        >
          <Link className="main-nav-link" onClick={toggleContactInfo}>
            <span
              className={
                showContactInfo ? "dropdown-arrow-up" : "dropdown-arrow-down"
              }
            >
              Contact Us
            </span>
          </Link>
        </li>
        <li>
          {showContactInfo && (
            <div className="sub-nav">
              <p className="main-nav-link">
                Email Us: indiahelpline@coca-cola.com
              </p>
              <p className="main-nav-link">
                Call Us Toll-free at: 1800 208 2653
              </p>
              <p className="main-nav-link">9:00 AM - 9:00 PM (Mon-Sat)</p>
              <p className="main-nav-link">9:00 AM - 6:00 PM (Sun)</p>
            </div>
          )}
        </li>
      </ul>
    </>
  );
}

export default OtherLinks;
