import React, { useEffect } from "react";
import { Button } from "react-bootstrap";

import AppModal from "../../components/modals";
import { useNavigate } from "react-router-dom";
import { onLoadCDP } from "../../utils/helpers/cds-helper";

function UniqueCodeFail({ show, onClose, setShowUniqueCode }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (show) {
      onLoadCDP("Forfeit_FitnessTracker");
    }
  }, [show]);

  return (
    <AppModal show={show} onClose={onClose} heading="#RukkMatt">
      <>
        <p className="mb-4">
          Your missed this chance at winning, but many more gears up for grabs,
          Just try again with a new code.
        </p>
        <Button
          className="btn btn-primary btn-block"
          onClick={() => {
            if (setShowUniqueCode && typeof setShowUniqueCode === "function") {
              setShowUniqueCode(true);
            }
          }}
        >
          Enter New Code
        </Button>
        <div className="border-dash my-6 mx-n3 mx-sm-n8"></div>
        <p className="mb-5">
          Also, don't miss out on your chance to win tickets to the ICC Men's
          Cricket World Cup 2023*!
        </p>
        <img
          className="mb-5 w-50"
          src="/assets/img/products/ticket.png"
          alt="Ticket"
        />

        <Button
          className="btn btn-primary btn-block"
          onClick={() => navigate("/yoyo")}
        >
          Take The yo-yo Test
        </Button>
      </>
    </AppModal>
  );
}

export default UniqueCodeFail;
