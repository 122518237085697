import dayjs from "dayjs";
import { State, City } from "country-state-city";
import toast from "react-hot-toast";

import { COUNTRY_CODE } from "../../config/app-config";
import { clickButtonCDP } from "./cds-helper";

/**
 * @method setFieldError
 * @description set react hook form field custom (manual) error
 * @param {*} param0
 */
export const setFieldError = ({ err, fieldName = "", setError }) => {
  const errMsg =
    err?.response?.data?.Data?.[0]?.Message || err?.response?.data?.Message;

  const PropertyName = err?.response?.data?.Data?.[0]?.PropertyName;

  if (errMsg) {
    setError(PropertyName || fieldName, { type: "custom", message: errMsg });
  }
};

/**
 * @method getStateList
 * @description return state list
 * @returns
 */
export const getStateList = () => {
  return State.getStatesOfCountry(COUNTRY_CODE);
};

/**
 * @method getCityList
 * @description return cities of the state
 * @param {*} stateCode
 */
export const getCityList = (stateCode = "") => {
  return City.getCitiesOfState(COUNTRY_CODE, stateCode);
};

/**
 * @method maskMobileNo
 * @description return masked mobile no
 * @param {*} MobileNo
 * @returns
 */
export const maskMobileNo = (MobileNo = "") => {
  return MobileNo?.substring(0, 4) + "XXXXXX";
};

/**
 * @method calculateTimeRemainingToClaim
 * @description return remaining time from the win to 48h
 * @param {*} winTime
 * @returns
 */
export const calculateTimeRemainingToClaim = (winTime) => {
  // format time
  function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  }

  const targetDate = dayjs(winTime);

  if (!winTime) {
    return { time: 0, formatTime, formattedTime: formatTime(targetDate) };
  }

  if (!targetDate.isValid()) {
    // when the provided date time is not valid date need in YYYY-MM-DDTHH:mm:hh
    return { time: 0, formatTime, formattedTime: formatTime(targetDate) };
  }

  const targetTime = targetDate.add(48, "hour");
  const currentTime = dayjs();
  const timeDifference = targetTime.diff(currentTime, "second");
  const time = Math.max(0, timeDifference);
  return { time, formatTime, formattedTime: formatTime(time) };
};

/**
 * @method isBlob
 * @description return boolean (T/F) if passed param is a blob object
 * @param {*} b
 * @returns
 */
export const isBlob = (b) => b instanceof Blob;

/**
 * @method downloadFileByAnchor
 * @description download image through anchor tag
 * @param {*} URL
 * @param {*} NAME
 * @param {*} FILE_EXTENSION
 */
export const downloadFileByAnchor = (
  URL,
  NAME = "file_name",
  FILE_EXTENSION = "jpeg"
) => {
  console.log("canvas.toDataURL(): ", URL);

  if (!URL || !NAME || !FILE_EXTENSION) {
    return;
  }

  // Create a download link for the canvas
  const downloadLink = document.createElement("a");
  downloadLink.href = URL;
  downloadLink.download = `${NAME}.${FILE_EXTENSION}`; // The name of the downloaded file
  document.body.appendChild(downloadLink);
  downloadLink.click(); // Simulate a click on the anchor tag to trigger the download
  document.body.removeChild(downloadLink); // Clean up by removing the anchor tag from the document
  clickButtonCDP("Generate_AR_Certificate-Download");
};

/**
 * @method handleShare
 * @description handle share on social
 * @param {*} param0
 */
export const handleShare = async ({
  title = "",
  text = "",
  image = "",
  url = window?.location?.href,
}) => {
  try {
    console.log("navigator.share: ", navigator.share);
    if (navigator.share) {
      const response = await fetch(image);
      const blob = await response.blob();
      const filesArray = [new File([blob], "image.png", { type: "image/png" })];

      const shareData = {
        title: title,
        text: text,
        files: filesArray,
        url: url,
      };

      await navigator.share(shareData);
      // Handle successful share
      await clickButtonCDP("Page_Share");
    } else {
      console.log("Don't have navigator.share", navigator.share);
      // Web Share API not supported
      // Display a tooltip or fallback share options
      toast.error("Web Share API not supported on your browser", {
        position: "top-center",
      });
    }
  } catch (error) {
    console.error("Error sharing:", error);
    // Handle error while sharing
  }
};

export function isGetUserMediaSupported() {
  return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
}

//mobile number validation
export const handleKeyPressMobileNumber = (event) => {
  console.log(event, "Input event triggered");
  const currentValue = event.target.value;
  // Remove any non-digit characters and limit the value to a maximum of 10 digits
  const filteredValue = currentValue.replace(/\D/g, "").slice(0, 10);

  // Check if the filtered value is '00000000' and handle accordingly
  if (/^0+$/.test(filteredValue)) {
    event.preventDefault();
    console.error("Invalid mobile number");
    return;
  }

  // Update the input value with the filtered value
  event.target.value = filteredValue;
};

export const handleKeyPressName = (event) => {
  const currentValue = event.target.value;
  // Remove any non-alphabetic characters
  const filteredValue = currentValue
    ?.replace(/[^A-Za-z ]+/g, "")
    ?.replace(/\s+/g, " ")
    ?.replace(/^\s+/, "");
  // Update the input value with the filtered value
  event.target.value = filteredValue;
};

//handle otp key press
export const handleKeyPressotp = (event) => {
  const currentValue = event.target.value;
  // Remove any non-digit characters and limit the value to a maximum of 6 digits
  const filteredValue = currentValue.replace(/\D/g, "").slice(0, 6);
  // Update the input value with the filtered value
  event.target.value = filteredValue;
};

export const handleKeyPressPinCode = (event) => {
  const currentValue = event.target.value;
  // Remove any non-digit characters and limit the value to a maximum of 6 digits
  const filteredValue = currentValue.replace(/\D/g, "").slice(0, 6);
  // Update the input value with the filtered value
  event.target.value = filteredValue;
};

// handle unique code key press
export const handleKeyPressUniqueCode = (event) => {
  const currentValue = event.target.value;
  // Remove more than 10 length
  let filteredValue = currentValue.replace(/[^a-zA-Z0-9]/g, "").slice(0, 100);

  if (currentValue.length > 10) {
    filteredValue = currentValue.slice(0, 10);
  }

  // Update the input value with the filtered value
  event.target.value = filteredValue;
};

export const preventRightClick = () => {
  if (typeof window === "undefined" || typeof document === "undefined") return;
  // Function to handle the contextmenu event
  function handleContextMenu(event) {
    event.preventDefault(); // Prevent the default right-click context menu
  }
  // Add the event listener to the document
  document.addEventListener("contextmenu", handleContextMenu);
};

export const getFileSizeInMB = (size = "") => {
  if (!size || typeof size !== "number") return;
  const sizeInMB = size * 0.000001;
  return sizeInMB.toFixed(1);
};

export const getUploadedFileDetail = (file) => {
  console.log("file: ", file);
  if (!file) return "";
  const fileSize = getFileSizeInMB(file?.size);
  const fileName = file?.name;
  return `${fileName ? `${fileName}, ` : ""} ${
    fileSize ? `${fileSize}MB` : ""
  }`;
};

export const getRecordedVideoDetails = (blob) => {
  if (!blob || !isBlob(blob)) return "";
  const fileSize = getFileSizeInMB(blob?.size);
  return `${fileSize ? `${fileSize}MB` : ""}`;
};

export function getVideoDuration(videoBlob) {
  return new Promise((resolve, reject) => {
    const video = document.createElement("video");
    video.preload = "metadata";
    video.controls = true; // This can help trigger metadata loading on Safari

    video.addEventListener("loadedmetadata", () => {
      const duration = video.duration;
      video.remove(); // Clean up the video element
      resolve(duration);
    });

    video.addEventListener("error", (error) => {
      video.remove(); // Clean up the video element
      // reject(error);
      resolve(0);
    });

    const videoUrl = URL.createObjectURL(videoBlob);
    video.src = videoUrl;

    // Append the video element to the DOM temporarily to trigger metadata loading
    document.body.appendChild(video);
  });
}


export const isCampaignEnded = () => {
  const currentDate = new Date();
  const targetDate = new Date('2023-11-01T00:00:00');
  console.log(currentDate,"date",targetDate,"condition",)
  return currentDate >= targetDate;
};