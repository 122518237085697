import React, { useEffect, useState } from 'react';
import AppModal from '../../components/modals';
import UniqueCodeForm from './unique-code-form';
import UniqueCodeSuccess from './unique-code-success';
import UniqueCodeFail from './unique-code-fail';
import { useDispatch, useSelector } from 'react-redux';
import { getUserClaimTime,setSmsLinkRedirection} from '../../store/slices/common-slice';
import { fetchUserById } from '../../store/slices/auth-slice';
import useAuth from '../../utils/hooks/use-auth';
import { checkQuota, pullUniqueCode } from '../../services/fitness-tracker-service';
import useLoader from '../../utils/hooks/use-loader';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import { TERMS_AND_CONDITIONS_URL } from '../../utils/constants';

function UniqueCode({ show, onClose }) {
  const dispatch = useDispatch();
  // const claimTime = useSelector((state) => state.common.claimTime);
  const timeRemain = useSelector((state) => state.common.timeRemain);
  const ipDetails = useSelector((state) => state.common.ipDetails);
  const { userDetails } = useAuth();
  const { toggleLoader } = useLoader();

  const [showUniqueCode, setShowUniqueCode] = useState(false);

  const [codeSuccess, setCodeSuccess] = useState(false);
  const [codeFailed, setCodeFailed] = useState(false);
  const [isHrDlQuotaFull, setIsHrDlQuotaFull] = useState(false);
  const [codeGeneratedPopup, setCodeGeneratedPopup] = useState(false);
  const [codeAlreadyGeneratedPopup, setCodeAlreadyGeneratedPopup] = useState(false);
  const [generatedUniqurCode, setGeneratedUniqueCode] = useState("")

  useEffect(() => {
    async function checkQuotaNow() {
      try {
        toggleLoader(true);
        const res = await checkQuota({
          UserId: userDetails?.Id,
        });
        console.log('res: ', res);
        if (res.status === 200) {
          setIsHrDlQuotaFull(null);
        }
      } catch (err) {
        const ErrorCode = err?.response?.data?.ErrorCode;
        const Message = err?.response?.data?.Message;
        console.log('err: ', err);
        if (ErrorCode && (ErrorCode === 702 || ErrorCode === 703)) {
          setIsHrDlQuotaFull(Message);
        } else {
          setIsHrDlQuotaFull(null);
        }
      } finally {
        toggleLoader(false);
      }
    }
    if (userDetails?.Id && show) {
      checkQuotaNow();
    }
  }, [userDetails?.Id, show, toggleLoader]);

  useEffect(() => {
    // fetch to get current status of fitbit of the current user
    dispatch(fetchUserById());
  }, [dispatch, show, codeSuccess]);

  useEffect(() => {
    if (!userDetails?.IsFitbitClaimTimeOver && !userDetails?.IsFitbitClaim) {
      dispatch(getUserClaimTime());
    }
  }, [
    dispatch,
    userDetails?.IsFitbitClaimTimeOver,
    userDetails?.IsFitbitClaim,
    show,
    codeSuccess,
  ]);

  useEffect(() => {
    if (
      timeRemain &&
      !userDetails?.IsFitbitClaimTimeOver &&
      !userDetails?.IsFitbitClaim
    ) {
      setCodeSuccess(true);
    } else {
      setShowUniqueCode(show);
    }
  }, [
    timeRemain,
    show,
    userDetails?.IsFitbitClaimTimeOver,
    userDetails?.IsFitbitClaim,
  ]);

  const handleClose = () => {
    if (codeSuccess) {
      onClose();
      // to hide the flip
      setTimeout(() => {
        setCodeSuccess(false);
        setCodeFailed(false);
      }, 500);
    }
    onClose();
    setShowUniqueCode(false);
    setCodeGeneratedPopup(false);
    setCodeAlreadyGeneratedPopup(false);
    setCodeFailed(false);
    setCodeSuccess(false);
    dispatch(setSmsLinkRedirection({ popup: "" , loginJourney: false}));
  };

  const handleCloseCodeSuccess = () => {
    onClose();
    setTimeout(() => {
      setCodeSuccess(false);
    }, 100);
  };

  const handleSuccessCode = () => {
    dispatch(fetchUserById());
    setCodeSuccess(true);
    setShowUniqueCode(false);
    onClose();
  };

  const handleUniqueCodeFail = () => {
    setCodeSuccess(false);
    setCodeFailed(true);
    setShowUniqueCode(false);
    onClose();
  };
  console.log(userDetails, "userDetailsuniq", ipDetails)
  const handleCodeGenerated = async () => {
    try {
      toggleLoader(true);

      const res = await pullUniqueCode({
        Id: userDetails && userDetails?.Id,
        MobileNo: userDetails && userDetails?.MobileNo,
        // StateId: "DL",
         StateId:  ipDetails && ipDetails?.region,
        Name: userDetails && userDetails?.Name ? userDetails?.Name : null,
        UniqueCode: ""
      });

      if (res.status === 200) {
        const uniqueCodeDetail = res && res?.data?.Data?.UniqueCode
        setShowUniqueCode(false);
        setCodeGeneratedPopup(show);
        setGeneratedUniqueCode(uniqueCodeDetail)
      }

    } catch (err) {
      const ErrorCode = err?.response?.status;
      const Message = err?.response?.data?.Message;
      console.log(err, "errorrrrr")
      console.log(Message, 'err unique111', ErrorCode);
      if (ErrorCode && (ErrorCode === 404)) {
        setShowUniqueCode(false);
        setCodeAlreadyGeneratedPopup(true)
      }
    } finally {
      toggleLoader(false);
    }
  };

  const renderUniqueCodeForm = () => {
    return (
      <>
        {isHrDlQuotaFull ? (
          <AppModal
            show={showUniqueCode}
            onClose={handleClose}
            restoreFocus={false}>
            <img
              src="/assets/img/gifs/limca-bottle.gif"
              alt="Limca Sportz Bottle"
              className="img-fluid mx-auto"
            />
            <h6 className="mb-0 mt-3" id="uniq-code-error">
              Oops, all Fitness Trackers for this hour have been won by other
              participants, please come back next hour to try again
            </h6>
          </AppModal>
        ) : (
          <AppModal
            show={showUniqueCode}
            onClose={handleClose}
            heading="
            Stand a chance to win a<br />
            Fitness Tracker every hour*!"
            restoreFocus={false}>
            <img
              src="/assets/img/gifs/limca-bottle.gif"
              alt="Limca Sportz Bottle"
              className="img-fluid mx-auto"
            />
            <p>
              Find the unique code behind the label of your <br />
              Limca<sup>&reg; </sup>
              Sportz bottle
            </p>
            <UniqueCodeForm
              onSuccess={handleSuccessCode}
              onFail={handleUniqueCodeFail}
              isHrDlQuotaFull={isHrDlQuotaFull}
              setShowUniqueCode={setShowUniqueCode}
              handleCodeGenerated={handleCodeGenerated}
            />
          </AppModal>
        )}
      </>
    );
  };

  //code generated popup
  const renderCodeGenratedPopup = () => {
    return (
      <>
        <AppModal show={codeGeneratedPopup} onClose={handleClose}>
          <img
            className="mb-4"
            src="/assets/img/products/codeGenerated.png"
            alt="Ticket"
          />
          <div className="row justify-content-center">
            <div className="co-12">
              <h3 className="modal-title modal-title-sm">
                Unique Code Generated
              </h3>
              <p className="my-4">
                Here is your Unique Code - {generatedUniqurCode ? generatedUniqurCode : "XXXXXXXXXX"} . The <br /> same  has been sent to you via SMS too.
              </p>
              <button
                type="submit"
                className="btn btn-primary w-100"
                onClick={() => handleClose()}>
                Ok
              </button>
              <p className="mt-4">
                You can generate a code only once per mobile number.
                <Link to={TERMS_AND_CONDITIONS_URL} target={"_blank"} className="link ml-1 text-primary text-nowrap" >T&C</Link>
              </p>
            </div>
          </div>
        </AppModal>
      </>
    );
  };

  //already code generated popup
  const renderAlredyCodeGenratedPopup = () => {
    return (
      <>
        <AppModal show={codeAlreadyGeneratedPopup} onClose={handleClose}>
          <img
            className="mb-4"
            src="/assets/img/products/alredyGeneratedCode.png"
            alt="Ticket"
          />
          <div className="row justify-content-center">
            <div className="col-12">
              <h3 className="modal-title modal-title-sm">Oops!</h3>
              <p className="mt-3">
                You have already generated a unique <br /> code via SMS.
              </p>
            </div>
          </div>
        </AppModal>
      </>
    );
  };

  if (
    timeRemain &&
    !userDetails?.IsFitbitClaimTimeOver &&
    !userDetails?.IsFitbitClaim
  ) {
    return (
      <UniqueCodeSuccess show={codeSuccess} onClose={handleCloseCodeSuccess} />
    );
  }

  if (showUniqueCode) {
    return renderUniqueCodeForm();
  } else if (codeGeneratedPopup) {
    return renderCodeGenratedPopup();
  } else if (codeAlreadyGeneratedPopup) {
    return renderAlredyCodeGenratedPopup();
  } else if (codeSuccess) {
    return (
      <UniqueCodeSuccess show={codeSuccess} onClose={handleCloseCodeSuccess} />
    );
  } else if (codeFailed) {
    return (
      <UniqueCodeFail
        show={codeFailed}
        onClose={handleClose}
        setShowUniqueCode={setShowUniqueCode}
      />
    );
  }
}

export default UniqueCode;
