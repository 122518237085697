import React from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

function Page404() {
  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center w-100 bg-pattern-2"
      style={{ minHeight: "100vh" }}
    >
      <h2>404</h2>
      <h5>Page Not Found</h5>
      <div className="my-3">
        <Link to="/">
          <Button>Go to Home Page</Button>
        </Link>
      </div>
    </div>
  );
}

export default Page404;
