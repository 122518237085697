import React, { useCallback, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import Webcam from "react-webcam";
import { Button } from "react-bootstrap";

import { downloadFileByAnchor } from "../../../utils/helpers";
import { generateARCertificate } from "../../../services/yoyo-service";
import useAuth from "../../../utils/hooks/use-auth";
import useLoader from "../../../utils/hooks/use-loader";
import useTimer from "../../../utils/hooks/use-timer";
import { clickButtonCDP } from "../../../utils/helpers/cds-helper";

const overlayImage = "/assets/img/common/certificate.png";
const overlayImage2 = "/assets/img/common/certificate3.png";

const GenerateARCertificate = ({ onSuccess, onClose }) => {
  const [certImage, setCertImage] = useState("");
  const { toggleLoader } = useLoader();
  const { userDetails } = useAuth();
  const webcamRef = React.useRef(null);
  const [facingMode, setFacingMode] = useState("user"); // "user" for front camera, "environment" for back camera
  const [showTimer, setShowTimer] = useState(false);
  const [isOn, setIsOn] = useState(false);

  const { time, startTimer, pauseTimer, resetTimer, formattedTimerTime } =
    useTimer(10, true);

  const videoConstraints = {
    facingMode: facingMode,
    width: 480,
    height: 480,
    aspectRatio: 1,
  };

  useEffect(() => {
    if (time === 0) {
      setShowTimer(false);
      capture();
    }
  }, [time]);

  const reportARCertificateGenerated = useCallback(async () => {
    toggleLoader(true);
    try {
      await generateARCertificate({ Id: userDetails?.Id });
    } catch (err) {
    } finally {
      toggleLoader(false);
    }
  }, [toggleLoader, userDetails?.Id]);

  const capture = () => {
    window.dataLayer.push({ event: "lmsyoyocertificatecapture" });
    const imageSrc = webcamRef.current.getScreenshot();
    console.log("imageSrc: ", imageSrc);

    const canvas = document.createElement("canvas");
    canvas.width = 540;
    canvas.height = 960;
    canvas.style.border = "1px solid black";
    const ctx = canvas.getContext("2d");
    console.log("canvas: ", canvas);

    const imgBase64 = new Image();
    const imgHTML = new Image();

    imgBase64.onload = function () {
      imgHTML.onload = function () {
        canvas.width = imgHTML.width;
        canvas.height = imgHTML.height;
        ctx.drawImage(imgBase64, 0, 690, 1080, 1080);

        ctx.drawImage(imgHTML, 0, 0, canvas.width, canvas.height);

        setCertImage(canvas.toDataURL());
      };
      imgHTML.src = overlayImage;
    };

    imgBase64.src = imageSrc;
  };

  const save = () => {
    window.dataLayer.push({ event: "lmsyoyocertificatesave" });
    clickButtonCDP("Generate_AR_Certificate");
    downloadFileByAnchor(certImage, "YOYO_CERTIFICATE", "jpeg");
    reportARCertificateGenerated();
    onSuccess();
  };

  if (certImage) {
    return (
      <>
        <img src={certImage} alt="Certificate" />
        <div className="btn-block">
          <button
            onClick={() => {
              save();
            }}
            className="btn btn-primary btn-sm"
          >
            Save
          </button>
          <button
            onClick={() => setCertImage("")}
            className="btn btn-primary btn-sm"
          >
            Retake
          </button>
        </div>
      </>
    );
  }

  const toggleFacingMode = () => {
    setFacingMode((prevMode) => (prevMode === "user" ? "environment" : "user"));
  };

  const renderSwitchCam = () => {
    if (!isMobile) return null;

    return (
      <Button
        className="text-nowrap"
        onClick={toggleFacingMode}
        disabled={showTimer}>
        Switch Camera
      </Button>
    );
  };

  const startCountDown = () => {
    setShowTimer(true);
    resetTimer();
    startTimer();
  };

  const handleToggle = () => {
    setIsOn(!isOn);
    setShowTimer(isOn);
  };
  return (
    <>
      <div className="ar-wrapper">
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          screenshotQuality={1}
          height={480}
          width={480}
          videoConstraints={videoConstraints}
          mirrored
        />

        <img id="overlay" src={overlayImage} alt="Cutout Neeraj Chopra" />
        {showTimer && time > 0 && (
          <h2
            className="count-down-timer display-1"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            {time}
          </h2>
        )}
        <div className="btn-block">
          {/* <button
            onClick={handleToggle}
            className="btn btn-primary btn-sm text-nowrap"
          >
            {isOn ? "Timer ON" : "Timer OFF"}
          </button> */}
          {isOn ? (
            <button
              onClick={startCountDown}
              className="btn btn-primary btn-sm"
              disabled={showTimer}
            >
              Capture
            </button>
          ) : (
            <button onClick={capture} className="btn btn-primary btn-sm">
              Capture
            </button>
          )}

          {/* {renderSwitchCam()} */}
        </div>
      </div>
    </>
  );
};

export const GenerateARCertificate2 = ({ onSuccess, onClose }) => {
  const [certImage, setCertImage] = useState("");
  const { toggleLoader } = useLoader();
  const { userDetails } = useAuth();
  const webcamRef2 = React.useRef(null);
  const [facingMode, setFacingMode] = useState("user"); // "user" for front camera, "environment" for back camera
  const [showTimer, setShowTimer] = useState(false);
  const [isOn, setIsOn] = useState(false);
  const { time, startTimer, pauseTimer, resetTimer, formattedTimerTime } =
    useTimer(10, true);

  const videoConstraints = {
    facingMode: facingMode,
    width: { ideal: 720 }, // Set an ideal width
    height: { ideal: 720 }, // Set an ideal height
    aspectRatio: 1,
    frameRate: 100,
  };

  useEffect(() => {
    if (time === 0) {
      setShowTimer(false);
      capture();
    }
  }, [time]);

  const reportARCertificateGenerated = useCallback(async () => {
    toggleLoader(true);
    try {
      await generateARCertificate({ Id: userDetails?.Id });
    } catch (err) {
    } finally {
      toggleLoader(false);
    }
  }, [toggleLoader, userDetails?.Id]);

  const capture = () => {
    window.dataLayer.push({ event: "lmsyoyocertificatecapture" });
    const imageSrc = webcamRef2.current.getScreenshot();
    console.log("imageSrc: ", imageSrc);

    const canvas = document.createElement("canvas");
    canvas.width = 540;
    canvas.height = 960;
    canvas.style.border = "1px solid black";
    const ctx = canvas.getContext("2d");
    console.log("canvas: ", canvas);

    const imgBase64 = new Image();
    const imgHTML = new Image();

    imgBase64.onload = function () {
      imgHTML.onload = function () {
        canvas.width = imgHTML.width;
        canvas.height = imgHTML.height;
        ctx.drawImage(imgBase64, 0, 288, 480, 480);

        ctx.drawImage(imgHTML, 0, 0, canvas.width, canvas.height);

        setCertImage(canvas.toDataURL());
      };
      imgHTML.src = overlayImage2;
    };

    imgBase64.src = imageSrc;
  };

  const save = () => {
    window.dataLayer.push({ event: "lmsyoyocertificatesave" });
    downloadFileByAnchor(certImage, "YOYO_CERTIFICATE", "jpeg");
    reportARCertificateGenerated();
    onSuccess();
  };

  if (certImage) {
    return (
      <>
        <img src={certImage} alt="Certificate" />
        <div className="btn-block">
          <button
            onClick={() => {
              save();
            }}
            className="btn btn-primary btn-sm"
          >
            Save
          </button>
          <button
            onClick={() => setCertImage("")}
            className="btn btn-primary btn-sm"
          >
            Retake
          </button>
        </div>
      </>
    );
  }

  const toggleFacingMode = () => {
    setFacingMode((prevMode) => (prevMode === "user" ? "environment" : "user"));
  };

  const renderSwitchCam = () => {
    if (!isMobile) return null;

    return (
      <Button
        className="text-nowrap"
        onClick={toggleFacingMode}
        disabled={showTimer}>
        Switch Camera
      </Button>
    );
  };

  const startCountDown = () => {
    setShowTimer(true);
    resetTimer();
    startTimer();
  };
  const handleToggle = () => {
    setIsOn(!isOn);
    setShowTimer(isOn);
  };
  return (
    <>
      <div className="wrapper">
        <Webcam
          audio={false}
          ref={webcamRef2}
          screenshotFormat="image/jpeg"
          screenshotQuality={1}
          videoConstraints={videoConstraints}
          mirrored
        />

        <img id="overlay" src={overlayImage2} alt="Cutout Neeraj Chopra" />
        {showTimer && time > 0 && (
          <h2
            className="count-down-timer display-1"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            {time}
          </h2>
        )}
        <div className="btn-block">
          <button
            onClick={handleToggle}
            className="btn btn-primary btn-sm text-nowrap"
          >
            {isOn ? "Timer ON" : "Timer OFF"}
          </button>
          {isOn ? (
            <button
              onClick={startCountDown}
              className="btn btn-primary btn-sm"
              disabled={showTimer}
            >
              Capture
            </button>
          ) : (
            <button onClick={capture} className="btn btn-primary btn-sm">
              Capture
            </button>
          )}
          {renderSwitchCam()}
        </div>
      </div>
    </>
  );
};

export default GenerateARCertificate;
