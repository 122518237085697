import { createContext, useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";

import FullScreenLoader from "../../components/loaders/full-screen-loader";

export const LoaderContext = createContext();

export const LoaderProvider = ({ children }) => {
  const loading = useSelector((state) => state.common.isLoading);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const toggleLoader = useCallback((action) => {
    setIsLoading((prevLoading) =>
      action !== undefined && typeof action === "boolean"
        ? action
        : !prevLoading
    );
  }, []);

  return (
    <LoaderContext.Provider value={{ isLoading, toggleLoader }}>
      {children}
      <FullScreenLoader isLoading={isLoading} />
    </LoaderContext.Provider>
  );
};
