import React, { useEffect, useState } from "react";
import AppModal from "../../components/modals";
import { useSelector } from "react-redux";
import { RESTRICTED_STATE, ELIGIBLE_COUNTRY } from "../../utils/constants/common-constants";

function RestrictRegion({ show = false, onClose }) {
    const [open, setOpen] = useState(show);
    const ipDetails = useSelector((state) => state.common.ipDetails);
    const regionName = ipDetails?.regionName ? ipDetails?.regionName : ""
    const countryName = ipDetails?.country ? ipDetails?.country : ""
    const restrictToRedirect = ipDetails && ipDetails.regionName === RESTRICTED_STATE ? true : false

    useEffect(() => {
        setOpen(show);
    }, [show]);

    const handleClose = () => {
        onClose();
        setOpen(false);
    };

    return (

        <AppModal
            show={open}
            onClose={handleClose}
            heading={`This promotion is not applicable in ${restrictToRedirect ? regionName : countryName}`}
            headingClass="text-warning"
        ></AppModal>
    );
}

export default RestrictRegion;
