import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import axios from "axios";

import {
  ReactSelectInput,
  SeparateInput,
  UploadInput,
} from "../../utils/form-inputs";
import YoYoTestRecording from "./recording/yoyo-test-recording";
import { yoyoTestFormValidationSchema } from "../../utils/validations";
import {
  getRecordedVideoDetails,
  getUploadedFileDetail,
  isBlob,
} from "../../utils/helpers";
import { registerYoYoTest } from "../../services/yoyo-service";
import useAuth from "../../utils/hooks/use-auth";
import useLoader from "../../utils/hooks/use-loader";
import { mediaUpload } from "../../services/media-service";
import { setRecording } from "../../store/slices/common-slice";
import ReCaptcha from "../../components/common/recaptcha";
import {
  SHUTTLES_COUNT,
  VIDEO_TIME_DURATION,
} from "../../utils/constants/common-constants";
import { clickButtonCDP } from "../../utils/helpers/cds-helper";
import { FLOODLIGHT_COUNTER, counterFloodLight } from "../../utils/helpers/floodlight-helper";

const isValidDuration = async (Level, videoToUpload) => {
  if (!videoToUpload || !Level) {
    return await new Promise((resolve, reject) => {
      resolve(false);
    });
  }
  // Create a video element
  const videoElement = document.createElement("video");

  // Load the video using the blob URL
  videoElement.src = URL.createObjectURL(videoToUpload);

  // Wait for the metadata to load (including duration)
  return await new Promise((resolve) => {
    videoElement.addEventListener("loadedmetadata", () => {
      // Get the video duration
      const videoDurationInSeconds = videoElement.duration;
      const maxAllowedDuration = VIDEO_TIME_DURATION[Level]; // 1 minute in seconds

      const isVal = videoDurationInSeconds > maxAllowedDuration ? true : false;

      return resolve(isVal);
    });
  });
};

function YoYoTestForm({ onSuccess }) {
  const dispatch = useDispatch();

  const { userDetails } = useAuth();
  const { toggleLoader } = useLoader();

  const reCaptchaRef = useRef();

  const [openRecording, setOpenRecording] = useState(false);
  const [compressing, setCompressing] = useState(false);

  const [compressedVid, setCompressedVid] = useState(null);

  const recordedBlob = useSelector((state) => state.common.recording);
  console.log("recordedBlob: ", recordedBlob);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    register,
    formState,
    setError,
    clearErrors,
    trigger,
  } = useForm({
    resolver: yupResolver(yoyoTestFormValidationSchema),
    defaultValues: {
      video: "",
      video_upload: "",
      Minutes: "",
      Seconds: "",
      Level: "Beginner",
      Shuttles: "02",
    },
    mode: "all",
  });

  console.log("formState errors: ", formState.errors);
  const selectedLevel = watch("Level");
  const videoUpload = watch("video_upload");
  const videoRecorded = watch("video");
  const minutesEntered = watch("Minutes");

  useEffect(() => {
    if (minutesEntered) {
      trigger("Seconds");
    }
  }, [minutesEntered, trigger]);

  console.log(
    "process.env.REACT_APP_COMPRESS_API_URL",
    process.env.REACT_APP_COMPRESS_API_URL
  );

  useEffect(() => {
    setValue("Shuttles", SHUTTLES_COUNT[selectedLevel] || "00");
  }, [selectedLevel, setValue]);

  useEffect(() => {
    if (recordedBlob && isBlob(recordedBlob)) {
      async function compress(file) {
        setCompressing(true);
        const FD = new FormData();
        FD.append("file", file);

        const result = await axios.post(
          process.env.REACT_APP_COMPRESS_API_URL,
          FD,
          {
            headers: { Accept: "video/mp4;charset=UTF-8" },
            responseType: "blob",
          }
        );

        const vBlob = await new Blob([result.data], { type: "video/mp4" });

        setCompressing(false);
        setValue("video", vBlob);
        return vBlob;
      }
      clearErrors("video");

      toast.promise(
        compress(recordedBlob),
        {
          loading: "Uploading...",
          success: <b>Uploaded!</b>,
          error: <b>Failed to Upload!</b>,
        },
        {
          position: "top-center",
          style: { top: "50%" },
          className: "rp-middle-toast",
        }
      );
    } else {
      setValue("video", "");
    }
  }, [recordedBlob, setValue, clearErrors]);

  useEffect(() => {
    async function compress(file) {
      setCompressing(true);
      const FD = new FormData();
      FD.append("file", file);

      const result = await axios.post(
        process.env.REACT_APP_COMPRESS_API_URL,
        FD,
        {
          headers: { Accept: "video/mp4;charset=UTF-8" },
          responseType: "blob",
        }
      );

      const vBlob = await new Blob([result.data], { type: "video/mp4" });

      setCompressing(false);
      setCompressedVid(vBlob);
      return vBlob;
    }
    if (videoUpload && videoUpload?.[0]) {
      isValidDuration(selectedLevel, videoUpload?.[0]).then((isValidDur) => {
        if (!isValidDur) {
          return setError("video_upload", {
            type: "custom",
            message: `Duration must be at least ${VIDEO_TIME_DURATION[selectedLevel]} sec.`,
          });
        }
        clearErrors("video_upload");
        // toast.promise(
        //   compress(videoUpload?.[0]),
        //   {
        //     loading: 'Compressing...',
        //     success: <b>Compressed!</b>,
        //     error: <b>Could not compress</b>,
        //   },
        //   {
        //     position: 'top-center',
        //     style: { top: '50%' },
        //     className: 'rp-middle-toast',
        //   }
        // );
      });
    }
    // else {
    //   setCompressedVid(null);
    // }
  }, [videoUpload, setError]);

  useEffect(() => {
    if (selectedLevel && videoUpload?.[0]) {
      clearErrors("video_upload");
      isValidDuration(selectedLevel, videoUpload?.[0]).then((isValidDur) => {
        if (!isValidDur) {
          return setError("video_upload", {
            type: "custom",
            message: `Duration must be at least ${VIDEO_TIME_DURATION[selectedLevel]} sec.`,
          });
        }
      });
    }
  }, [selectedLevel]);

  useEffect(() => {
    toggleLoader(compressing);
  }, [compressing, toggleLoader]);

  const onSubmitForm = async (data) => {
    console.log("data: ", data);

    try {
      // upload media and get video url back
      // const videoToUpload = data.video ? data.video : compressedVid;
      // console.log('videoToUpload: ', videoToUpload);
      const videoToUpload = data.video ? recordedBlob : data.video_upload?.[0];
      if (!videoToUpload) {
        return setError("video_upload", {
          type: "custom",
          message: "Upload or Record a video",
        });
      }

      console.log("Duration Pass");

      toggleLoader(true);

      const mediaFormData = new FormData();
      mediaFormData.append("file", videoToUpload);
      mediaFormData.append("userId", userDetails.Id);

      const mediaRes = await mediaUpload(mediaFormData);

      if (mediaRes.status !== 200) {
        // eslint-disable-next-line no-throw-literal
        throw "Error while uploading video";
      }

      // attach return video url in data
      const VideoUrl = mediaRes.data?.Data?.videoUrl;

      const reqData = {
        UserId: userDetails.Id,
        VideoUrl,
        YoYoLevel: data.Level,
        TotalTimeTaken: `${data.Minutes}:${data.Seconds}`,
        TotalShuttle: SHUTTLES_COUNT[data.Level],
      };
      console.log(reqData, "reqData")
      const res = await registerYoYoTest(reqData);
      if (res.status === 200) {
        const yoyoSubmitEvent = {
          Beginner: "lmsyoyobeginnersubmit",
          Intermediate: "lmsyoyointermediatesubmit",
          Advanced: "lmsyoyoadvancesubmit",
        };
        window.dataLayer.push({ event: yoyoSubmitEvent[data.Level] });
        onSuccess();
        dispatch(setRecording(null));
      }
    } catch (err) {
      console.log(err,"Errrrrr")
      const ErrorCode =
        err?.response?.data?.ErrorCode ||
        err?.response?.data?.ResponseCode ||
        err?.status;
      const ErrorMsg =
        err?.response?.data.Data?.[0]?.Message ||
        err?.response?.data?.Message ||
        err?.response?.data;
      toast.error(`${ErrorCode} - ${ErrorMsg}`);
    } finally {
      toggleLoader(false);
    }
  };

  const renderFileRecordDetail = () => {
    if (formState?.errors?.video) {
      return (
        <p className="text-truncate btn-wrapper-msg btn-wrapper-msg-1">
          {formState?.errors?.video}
        </p>
      );
    }
    const recordingDetails = getRecordedVideoDetails(videoRecorded);
    if (recordedBlob && recordingDetails) {
      return (
        <p
          className="text-truncate btn-wrapper-msg btn-wrapper-msg-1"
          title={`Recording Size: ${recordingDetails}`}
        >
          {`Recording Size: ${recordingDetails}`}
        </p>
      );
    }
  };

  const renderFileUploadDetail = () => {
    const uploadDetail = getUploadedFileDetail(
      compressedVid || videoUpload?.[0]
    );
    if (uploadDetail) {
      return (
        <p
          className="text-truncate btn-wrapper-msg"
          title={`Uploaded: ${uploadDetail} `}
        >
          {`Uploaded: ${uploadDetail} `}
        </p>
      );
    }
  };

  const isMaxFileUploadSizeReached = () => {
    if (formState?.errors?.video_upload?.type === "file_size") {
      return true;
    }
    return false;
  };

  return (
    <>
      <div className="yoyo-form bg-blur">
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <ul className="list py-6">
            <li className="list-item">
              <h4 className="text-primary">Select level</h4>
              <div className="list-input-wrapper">
                <div className="form-group yoyo-select-wrap">
                  <ReactSelectInput
                    control={control}
                    name="Level"
                    options={[
                      { label: "Beginner", value: "Beginner" },
                      { label: "Intermediate", value: "Intermediate" },
                      { label: "Advanced", value: "Advanced" },
                    ]}
                    inputProps={{
                      placeholder: "Level*",
                    }}
                  />
                </div>
              </div>
            </li>
            <li className="list-item">
              <div>
                <h4 className="text-primary mb-1">
                  Record Your Yo-Yo Test video
                </h4>
                <p
                  className={`mb-5 mb-lg-0 mr-lg-2 small ${isMaxFileUploadSizeReached() ? "text-danger" : ""
                    } `}
                >
                  Maximum file size for upload is 500 MB.
                </p>
              </div>
              <div className="list-input-wrapper position-relative">
                <div className="btn-wrapper-outer w-100">
                  <div className="position-relative">
                    <Link
                      onClick={() => setOpenRecording(true)}
                      className="btn-wrapper"
                    >
                      <img
                        className="btn-icon"
                        src="/assets/img/icons/video-record.svg"
                        alt="record"
                      />
                      <span className="btn-text">Record</span>
                    </Link>

                    {renderFileRecordDetail()}
                  </div>
                  <UploadInput
                    name="video_upload"
                    inputProps={{
                      accept: "video/*",
                    }}
                    register={register}
                    error={formState.errors.video_upload}
                    customMessage={renderFileUploadDetail()}
                  />
                </div>
              </div>
            </li>
            <li className="list-item">
              <h4 className="text-primary">Enter total time taken</h4>
              <div className="list-input-wrapper">
                <div className="form-control-wrapper position-relative">
                  <SeparateInput
                    control={control}
                    name="Minutes"
                    inputClass="form-control-xs"
                    numInputs={2}
                    placeholder="MM"
                  />
                </div>
                <p className="text-primary form-saprator px-1 px-sm-2 px-md-4 m-0 d-inline-block">
                  :
                </p>
                <div className="form-control-wrapper position-relative">
                  <SeparateInput
                    control={control}
                    name="Seconds"
                    inputClass="form-control-xs"
                    numInputs={2}
                    placeholder="SS"
                  />
                </div>
              </div>
            </li>
            <li className="list-item">
              <h4 className="text-primary">No. of shuttles covered</h4>
              <div className="list-input-wrapper">
                <div className="form-control-wrapper">
                  <SeparateInput
                    control={control}
                    name="Shuttles"
                    inputClass="form-control-xs"
                    numInputs={2}
                    readOnly
                  />
                </div>
              </div>
            </li>
            <li className="list-item">
              <Button
                type="submit"
                className="flex-grow-0 mx-auto px-10 px-md-13"
                onClick={() => counterFloodLight(FLOODLIGHT_COUNTER.LimcaSportz_ICC23_YoYoSubmit_Counter)}
              >
                Submit
              </Button>
            </li>
          </ul>
        </form>
        <ReCaptcha reCaptchaRef={reCaptchaRef} />
      </div>
      <YoYoTestRecording
        show={openRecording}
        onClose={() => setOpenRecording(false)}
        Level={selectedLevel}
      />
    </>
  );
}

export default YoYoTestForm;
