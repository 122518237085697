import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import usePopup from '../../../utils/hooks/use-popup';

function NotShortlisted() {
  const navigate = useNavigate();
  const { openGenerateARCertificatePopup } = usePopup();
  return (
    <>
      <Card.Title as="h3">#RUKKMATT</Card.Title>
      <p className="mb-4 mb-sm-6 text-capitalize">
        Looks like your video was not shortlisted, but don't worry! Just retake
        the #YoYoTest for #LimcaSportz to win ICC Men's Cricket World Cup 2023
        tickets*
      </p>
      <button
        className="btn btn-primary mx-auto"
        onClick={() => navigate('/yoyo')}>
        Retake The test
      </button>
      <Row className="mt-7 mt-sm-11 mx-sm-n5 align-items-center align-items-lg-strech">
        <Col xs="5">
          <img src="/assets/img/common/certificate.png" alt="Certificate" />
        </Col>
        <Col
          xs="7"
          className="text-left d-flex flex-column justify-content-between">
          <h4 className="text-capitalize text-primary mb-md-5">
            get your Yo-Yo Test certificate with Neeraj Chopra
          </h4>
          <div>
            <h4 className="text-uppercase">Just smile and snap!</h4>
            <button
              className="btn btn-outline-primary px-3 px-lg-6"
              onClick={openGenerateARCertificatePopup}>
              Generate Now
            </button>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default NotShortlisted;
