import { createContext, useState, useCallback, useEffect } from 'react';
import Auth from '../../features/auth';
import UniqueCode from '../../features/unique-code';
import Claim from '../../features/claim';
import useAuth from '../hooks/use-auth';
import { useNavigate } from 'react-router-dom';
import { CLAIM_TYPE } from '../constants/common-constants';
import GenerateCertificate from '../../features/yoyo-test/generate-certificate';
import UniqueCodeClaimTimeOut from '../../features/unique-code/unique-code-claim-time-out';
import RestrictRegion from '../../features/auth/restrictRegion';

export const PopupContext = createContext();

export const PopupProvider = ({ children }) => {
  const navigate = useNavigate();

  const { isLoggedIn, userDetails } = useAuth();
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [showUniqueCodePopup, setShowUniqueCodePopup] = useState(false);
  const [showClaimPopup, setShowClaimPopup] = useState(false);
  const [claimType, setClaimType] = useState(CLAIM_TYPE.FIT_BIT);
  const [showGenerateARCertificatePopup, setShowGenerateARCertificatePopup] =
    useState(false);
  const [showRestrictRegion, setShowRestrictRegion] = useState(false);
  const [afterLoginAction, setAfterLoginAction] = useState('');

  const openLoginPopup = useCallback((action = true, afterLoginAc = '') => {
    setShowLoginPopup((prevLoading) =>
      action !== undefined && typeof action === 'boolean'
        ? action
        : !prevLoading
    );
    setAfterLoginAction(afterLoginAc);
  }, []);

  const closeLoginPopup = useCallback(() => {
    setShowLoginPopup(false);
    setAfterLoginAction('');
  }, []);

  const openUniqueCodePopup = useCallback(
    (action) => {
      if (!isLoggedIn) {
        return openLoginPopup();
      } else if (
        userDetails?.IsFitbitClaim ||
        (userDetails?.IsFitnessTrackerWon &&
          !userDetails?.IsFitbitClaimTimeOver)
      ) {
        return navigate('/my-winnings', { replace: true });
      }

      setShowUniqueCodePopup((prevLoading) =>
        action !== undefined && typeof action === 'boolean'
          ? action
          : !prevLoading
      );
    },
    [
      isLoggedIn,
      navigate,
      openLoginPopup,
      userDetails?.IsFitnessTrackerWon,
      userDetails?.IsFitbitClaimTimeOver,
      userDetails?.IsFitbitClaim,
    ]
  );

  const closeUniqueCodePopup = useCallback(() => {
    setShowUniqueCodePopup(false);
  }, []);

  const openClaimPopup = useCallback(
    (type) => (action) => {
      if (!isLoggedIn) {
        return openLoginPopup();
      }
      setClaimType(type);
      setShowClaimPopup((prevLoading) =>
        action !== undefined && typeof action === 'boolean'
          ? action
          : !prevLoading
      );
    },
    [isLoggedIn, openLoginPopup]
  );

  const closeClaimPopup = useCallback(() => {
    setShowClaimPopup(false);
  }, []);

  const openGenerateARCertificatePopup = useCallback((action) => {
    console.log('action',action)
    setShowGenerateARCertificatePopup((prevLoading) =>
      action !== undefined && typeof action === 'boolean'
        ? action
        : !prevLoading
    );
  }, []);
  const closeARGenerateCertificatePopup = useCallback(() => {
    setShowGenerateARCertificatePopup(false);
  }, []);
  const openRestrictRegionPopup = useCallback((action) => {
    setShowRestrictRegion((prevLoading) =>
      action !== undefined && typeof action === 'boolean'
        ? action
        : !prevLoading
    );
  }, []);

  const closeRestrictRegionPopup = useCallback(() => {
    setShowRestrictRegion(false);
  }, []);

  useEffect(() => {
    if (isLoggedIn && afterLoginAction) {
      console.log('afterLoginAction: ', afterLoginAction);
      if (afterLoginAction === 'openUniqueCodePopup') {
        openUniqueCodePopup();
      } else if (afterLoginAction === '/yoyo') {
        navigate('/yoyo');
      } else if (afterLoginAction === '/on-ground') {
        navigate('/on-ground');
      } else if (afterLoginAction === '/on-college') {
        navigate('/on-college');
      }else if (afterLoginAction === '/cultfit-onground') {
        navigate('/cultfit-onground');
      }
    }
  }, [isLoggedIn, afterLoginAction, navigate, openUniqueCodePopup]);

  console.log('showClaimPopup',showClaimPopup)

  return (
    <PopupContext.Provider
      value={{
        openLoginPopup,
        openUniqueCodePopup,
        openClaimPopup,
        openGenerateARCertificatePopup,
        openRestrictRegionPopup,
      }}>
      {children}
      <Auth show={showLoginPopup} onClose={closeLoginPopup} />

      <UniqueCode show={showUniqueCodePopup} onClose={closeUniqueCodePopup} />

      <Claim show={showClaimPopup} onClose={closeClaimPopup} type={claimType} />
      <GenerateCertificate
        show={showGenerateARCertificatePopup}
        onClose={closeARGenerateCertificatePopup}
      />
      <RestrictRegion
        show={showRestrictRegion}
        onClose={closeRestrictRegionPopup}
      />
      {isLoggedIn && <UniqueCodeClaimTimeOut />}
    </PopupContext.Provider>
  );
};
