import React, { useEffect, useState } from "react";
import AppModal from "../../components/modals";
import GenerateARCertificate from "./ar-certificate";
import { clickButtonCDP } from "../../utils/helpers/cds-helper";
import { setSmsLinkRedirection } from '../../store/slices/common-slice';
import { useDispatch } from "react-redux";

function GenerateCertificate({ show = false, onClose }) {
  const [open, setOpen] = useState(show);
  const dispatch = useDispatch()

  useEffect(() => {
    setOpen(show);
  }, [show]);

  useEffect(() => {
    if (open) {
      clickButtonCDP("Explore_AR_Certificate");
    }
  }, [open]);

  const handleClose = () => {
    onClose();
    setOpen(false);
    dispatch(setSmsLinkRedirection({ popup: '',loginJourney: false }))
  };

  return (
    <AppModal
      show={open}
      onClose={handleClose}
      bodyClass="p-0"
      headerClass="p-0"
      contentClassName="p-0 bg-transparent"
      className="ar-certificate-modal"
    >
      <GenerateARCertificate onSuccess={onClose} onClose={onClose} />
    </AppModal>
  );
}

export default GenerateCertificate;
