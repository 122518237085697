import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

import { GOOGLE_CAPTCHA_KEY } from "../../config/app-config";

function ReCaptcha({ reCaptchaRef, ...rest }) {
  return (
    <ReCAPTCHA
      size="invisible"
      {...rest}
      ref={reCaptchaRef}
      sitekey={GOOGLE_CAPTCHA_KEY}
    />
  );
}

export default ReCaptcha;
