import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import AppModal from '../modals';
import { TextInput } from '../../utils/form-inputs';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { setAdminDetails, setPromoterDetails } from '../../store/slices/common-slice';
import { adminLoginValidationSchema } from '../../utils/validations';
import { useLocation } from 'react-router-dom';

const AdminLogin = ({ onLoginSuccess, setLoggedIn, handleClose,route}) => {
    const dispatch = useDispatch()
    const {pathName} = useLocation()
    const { handleSubmit, control, setError } = useForm({
        resolver: yupResolver(adminLoginValidationSchema),
    });
const popupHeading = route === "admin" ? "Admin Login" :"Promoter Login"

    const onSubmit = (data) => {
        console.log(data, "data")
        if (data?.Email
            === 'admin@yopmail.com' && data?.Password === 'admin@123' && route === "admin") {
            dispatch(setAdminDetails({ adminDetails: data }))
            dispatch(setAdminDetails({ adminReportRoute: "GetOnGroundDataList" }))
            setLoggedIn(false)
            onLoginSuccess();
        } else if (data?.Email
            === 'promoter@yopmail.com' && data?.Password === 'promoter@123' && route === "promoter") {
            dispatch(setPromoterDetails({ promoterDetails: data }))
            setLoggedIn(false)
            onLoginSuccess();
        } else {
            // alert('Invalid Email or Password');
            setError("Password", {
                message: "Invalid Email or Password",
            });
        }
    };
    console.log("heading",route)
    return (
        <>
            <AppModal show={setLoggedIn} onClose={handleClose()} heading={popupHeading} subHeading="Enter Credentials">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                        <TextInput
                            control={control}
                            name="Email"
                            type="text"
                            inputProps={{
                                placeholder: "Enter Email",
                            }}
                        />
                        <br />
                    </div>
                    <div className="form-group">
                        <TextInput
                            control={control}
                            name="Password"
                            type="Password"
                            inputProps={{
                                placeholder: "Enter Password",
                            }}
                        />
                    </div>
                    <button type="submit" className="btn btn-primary btn-block">
                        Submit
                    </button>
                </form>
            </AppModal>
        </>


    );
};

export default AdminLogin;
