import React, { useCallback, useEffect, useState } from "react";
import useLoader from "../../utils/hooks/use-loader";
import { getWinnerList } from "../../services/winner-service";
import { Button, Col, Row } from "react-bootstrap";
import { winnerListDummydata } from "../../utils/constants";

function WinnersList() {
  const { toggleLoader } = useLoader();
  const [winnerList, setWinnerList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize] = useState(10); // Default page size, can be modified as needed
  const [totalRecords, setTotalRecords] = useState(0);

  const fetchWinnerList = useCallback(async () => {
    toggleLoader();
    try {
      const res = await getWinnerList({
        PageNo: pageNo,
        PageSize: pageSize,
      });
      console.log(res?.data?.Data, "res")
      if (res.status === 200) {
        const data = res?.data?.Data
        const WinnerList = data?.DataList
        // dummy data 
        // const data = winnerListDummydata?.TotalCount
        // const WinnerList = winnerListDummydata?.DataList

        const TotalRow = data?.TotalCount;

        if (WinnerList?.length) {
          setWinnerList(WinnerList);
          setTotalRecords(TotalRow);
        }
      }
    } catch (err) {
      // Handle error
    }
    finally {
      toggleLoader(false);
    }
  }, [pageNo, pageSize]);

  useEffect(() => {
    fetchWinnerList();
  }, [fetchWinnerList]);

  const handlePrevious = () => {
    if (pageNo > 1) {
      setPageNo((prevPage) => prevPage - 1);
    }
  };

  const handleNext = () => {
    const maxPage = Math.ceil(totalRecords / pageSize);
    if (pageNo < maxPage) {
      setPageNo((prevPage) => prevPage + 1);
    }
  };
  console.log(winnerList, "winnerList")
  return (
    <>
      {winnerList.length === 0 ? (
        <h3 className="justify-content-center text-center mt-14 text-primary">No data found.</h3>
      ) : (
        <><Row className="justify-content-center text-center">
            <Col xs={10} sm={7} className="mt-10 mt-sm-14 pt-sm-4">
              <h1 className="mb-3 mb-md-5">Our Champs</h1>
              <p>Here’s a list of our winners</p>
            </Col>
          </Row><Row className="justify-content-center text-center">
              <Col xs={12} md={10}>
                <div className="winner-wrapper">
                  <ul className="winner-list">
                    <li className="winner-list-item winner-list-item-header">
                      <span>S.No</span>
                      <span>Avatar</span>
                      <span>Name</span>
                      <span>Prize Won</span>
                    </li>
                    {winnerList?.map((data, index) => (
                      <li className={index % 2 === 0
                        ? "winner-list-item"
                        : "winner-list-item"}>
                        <span>{index + 1}.</span>
                        <span>
                          <img
                            className="winner-icon"
                            src={data.ProfileImg
                              ? data.ProfileImg
                              : '/assets/img/avatars/default-user.svg'}
                            alt="avatar" />
                        </span>
                        <span className="text-truncate" title="abc">
                          {data.Name ? data.Name : "N/A"}
                        </span>
                        <span>Fitness Tracker</span>
                      </li>
                    ))}

                  </ul>
                  {totalRecords > 10 &&
                    <div className="d-flex justify-content-center view-link">
                      <Button
                        variant="link"
                        size="sm"
                        className="link mt-5"
                        onClick={handlePrevious}
                        disabled={pageNo === 1}
                      >
                        Prev
                      </Button>
                      <Button
                        variant="link"
                        size="sm"
                        onClick={handleNext}
                        disabled={pageNo === Math.ceil(totalRecords / pageSize)}
                        className="link mt-5"
                      >
                        Next
                      </Button>
                    </div>}
                </div>
              </Col>
            </Row></>
      )}
    </>
  );
}

export default WinnersList;
