import React from 'react';
import { Navigate } from 'react-router-dom';
import { Col, Row, Button } from 'react-bootstrap';
import useAuth from '../utils/hooks/use-auth';
import LeaderBoard from '../features/leader-board';

function LeaderBoardPage() {
  const { isLoggedIn } = useAuth();

  if (!isLoggedIn) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="container-fluid">
      <Row className="justify-content-center text-center">
        <Col xs={10} sm={7} className="mt-10 mt-sm-14 pt-sm-4">
          <h1 className="mb-3 mb-md-5">Leaderboard</h1>
          <p>Here’s the list of winners </p>
        </Col>
      </Row>
      {/* <p> Back</p> */}
      <LeaderBoard />
    </div>
  );
}

export default LeaderBoardPage;
