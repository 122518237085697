export const VALIDATION_MSG = {
  required: {
    Name: "Please Enter Name",
    MobileNo: "Please Enter Mobile Number",
    OTP: "Please Enter OTP",
    Email: "Please Enter Email Address",
    ProfileImg: "Please Choose Avatar",
    Code: "Please Enter Code",
    AddressLine1: "Please Enter Address",
    State: "Please Select State",
    City: "Please Select City",
    PinCode: "Please Enter Pin code",
    Minutes: "Please Enter Minutes",
    Seconds: "Please Enter Seconds",
    Level: "Please Select Level",
    Shuttles: "Enter number of Shuttles",
    video_upload: "Upload or Record a video",
    Venue: "Please Enter Venue",
    Date: "Please Enter Date",
  },
  valid: {
    MobileNo: "*Please enter valid Mobile Number",
    OTP: "*Please enter a valid OTP",
    Email: "*Please enter a valid email address",
    Code: "*Please enter a valid unique code",
    PinCode: "Please enter a valid Pin code",
    Video_Type: "Please upload video only",
    Name: "Please enter a valid Name",
    Date: "Please enter a valid Date",
  },
};
