import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';

import { uniqueCodeValidationSchema } from '../../utils/validations';
import { TextInput } from '../../utils/form-inputs';
import useLoader from '../../utils/hooks/use-loader';
import { verifyUniqueCode } from '../../services/fitness-tracker-service';
import { handleKeyPressUniqueCode, setFieldError } from '../../utils/helpers';
import {
  FLOODLIGHT_COUNTER,
  counterFloodLight,
} from '../../utils/helpers/floodlight-helper';
import { MetaPixel } from '../../utils/helpers/metaHelper';
import { CODE_NOT_FOUND_REGION } from '../../utils/constants';
import { Link } from 'react-router-dom';

function UniqueCodeForm({
  onSuccess,
  onFail,
  isHrDlQuotaFull,
  handleCodeGenerated,
}) {
  const { toggleLoader } = useLoader();
  const userDetails = useSelector((state) => state.auth.userDetails);
  const ipDetails = useSelector((state) => state.common.ipDetails);
  const isCodeNotFountRegion = CODE_NOT_FOUND_REGION.includes(
    ipDetails?.region
  );

  const { handleSubmit, control, setError } = useForm({
    resolver: yupResolver(uniqueCodeValidationSchema),
    defaultValues: {
      Code: '',
    },
  });

  const onSubmit = async (values) => {
    toggleLoader(true);
    try {
      const reqData = {
        ...values,
        UserId: userDetails?.Id,
      };
      const res = await verifyUniqueCode(reqData);
      if (res.status === 200) {
        window.dataLayer.push({ event: 'lmsuniquecodevalidsubmit' });
        counterFloodLight(
          FLOODLIGHT_COUNTER.LimcaSportz_ICC23_EnterCode_Counter
        );
        MetaPixel('Submit10DigitUniqueCode');
        onSuccess(true);
      } else if (res.data?.ErrorCode === 705) {
        onFail();
      }
    } catch (err) {
      const ErrorCode = err.response.data.ErrorCode;
      console.log('ErrorCode: ', ErrorCode);
      if (ErrorCode === 705) {
        onFail();
        return;
      }
      setFieldError({ err, fieldName: 'Code', setError });
    } finally {
      toggleLoader(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <TextInput
            control={control}
            name="Code"
            inputProps={{
              placeholder: isHrDlQuotaFull
                ? isHrDlQuotaFull
                : 'Enter 10 Digit Unique Code',
              maxLength: 10,
              disabled: isHrDlQuotaFull ? true : false,
            }}
            onInput={handleKeyPressUniqueCode}
          />
        </div>
        <button
          type="submit"
          className="btn btn-primary btn-block"
          disabled={isHrDlQuotaFull ? true : false}>
          Submit
        </button>
      </form>
      {isCodeNotFountRegion && (
        <p className="mt-4 sm">
          Unable to find a Unique Code on the Limca Sportz Bottle?
          <Link
            onClick={() => handleCodeGenerated()}
            className="link ml-1 text-primary text-nowrap">
            Get a code here
          </Link>
        </p>
      )}
    </>
  );
}

export default UniqueCodeForm;
