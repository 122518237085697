import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";

import { signUpValidationSchema } from "../../utils/validations";
import { TextInput } from "../../utils/form-inputs";
import useLoader from "../../utils/hooks/use-loader";
import {
  handleKeyPressMobileNumber,
  handleKeyPressName,
  setFieldError,
} from "../../utils/helpers";
import { registerUser } from "../../services/user-service";
import ProfileImageSelector from "../../components/common/profile-image-selector";
import {
  PRIVACY_POLICY_URL,
  TERMS_AND_CONDITIONS_URL,
} from "../../utils/constants";
import { FLOODLIGHT_COUNTER, counterFloodLight } from "../../utils/helpers/floodlight-helper";
import { MetaPixel } from "../../utils/helpers/metaHelper";

function SignUp({ MobileNo, onSuccess }) {
  const { toggleLoader } = useLoader();
  const ipDetails = useSelector((state) => state.common.ipDetails);

  const {
    handleSubmit,
    control,
    setError,
    setValue,
    watch,
    getFieldState,
    formState,
    register,
  } = useForm({
    resolver: yupResolver(signUpValidationSchema),
    defaultValues: {
      ProfileImg: "",
      Name: "",
      Email: "",
      MobileNo: MobileNo,
      AgreeTerms: true,
    },
    mode: "all",
  });

  // fetched the profile image field state from form state
  const profileState = getFieldState("ProfileImg", formState);

  const onSubmit = async (values) => {
    counterFloodLight(FLOODLIGHT_COUNTER.LimcaSportz_ICC23_SignUp_Counter);
    if (!ipDetails || !ipDetails?.city || !ipDetails?.regionName) {
      return toast.error("Can't fetch your state and city through your IP");
    }

    toggleLoader(true);
    try {
      const reqData = {
        MobileNo,
        ProfileImg: "",
        ...values,
        State: ipDetails?.regionName,
        StateCode: ipDetails?.region,
        City: ipDetails?.city,
        Latitude: ipDetails?.lat,
        Longitude: ipDetails?.lon,
        PinCode: ipDetails?.zip,
        Ip: ipDetails?.query,
      };
      const res = await registerUser(reqData);
      if (res.status === 200) {
        MetaPixel('SubmitNameandEmail');
        window.dataLayer.push({ event: "lmssignup" });
        const data = res?.data?.Data;
        onSuccess(data);
      }
    } catch (err) {
      setFieldError({ err, fieldName: "", setError });
    } finally {
      toggleLoader(false);
    }
  };

  const handleProfileChange = (img) => {
    setValue("ProfileImg", img);
  };

  return (
    <>
      <div>
        <ProfileImageSelector
          selected={watch("ProfileImg")}
          onChange={handleProfileChange}
          error={!watch("ProfileImg") && profileState?.error?.message}
          dropPosition="down-centered"
        />
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="form-group-vs-sm">
        <div className="form-group">
          <TextInput
            control={control}
            name="Name"
            type="text"
            inputProps={{
              placeholder: "Name",
              autoComplete: "off",
            }}
            onInput={handleKeyPressName}
          />
        </div>
        <div className="form-group">
          <TextInput
            control={control}
            name="Email"
            type="email"
            inputProps={{
              placeholder: "Email",
              autoComplete: "off",
            }}
          />
        </div>

        <div className="form-group">
          <input
            type="checkbox"
            name="AgreeTerms"
            className="form-check-input"
            id="t_and_c"
            {...register("AgreeTerms")}
          />

          <label className="form-check-label mb-0" htmlFor="t_and_c">
            I certify that I am at least 18 years old and agree with the{" "}
            <a
              onClick={() => {
                counterFloodLight(FLOODLIGHT_COUNTER.LimcaSportz_ICC23_TC_Counter);
              }}
              href={TERMS_AND_CONDITIONS_URL}
              target={"_blank"}
              className="link"
              rel="noreferrer"
            >
              T&C
            </a>{" "}
            and{" "}
            <a
              href={PRIVACY_POLICY_URL}
              onClick={() => {
                counterFloodLight(FLOODLIGHT_COUNTER.LimcaSportz_ICC23_PrivacyPolicy_Counter);
              }}
              target={"_blank"}
              className="link"
              rel="noreferrer"
            >
              {" "}
              Privacy Policy
            </a>
          </label>
          {formState.errors.AgreeTerms && (
            <p className="invalid-feedback position-static d-block mt-2">
              {formState.errors.AgreeTerms.message}
            </p>
          )}
        </div>

        <button type="submit" className="btn btn-primary btn-block">
          Submit
        </button>
      </form>
    </>
  );
}

export default SignUp;
