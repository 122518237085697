import axios from "axios";

import { apiErrorHandler } from "./api-error-hander";
import { API_BASE_URL } from "../../config/app-config";
import { store } from "../../store";
import { logoutUser } from "../../store/slices/auth-slice";

// axios instance
export const AxiosInstance = axios.create({
  baseURL: `${API_BASE_URL}/api`,
});

// Add a request interceptor
AxiosInstance.interceptors.request.use(
  function (config) {
    // Do something before request is sent

    const Token = store.getState()?.auth?.userDetails?.Token;

    if (Token) {
      config.headers.Authorization = `bearer ${Token}`;
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
AxiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    console.log("error: ", error?.response?.status);
    if (error?.response?.status === 401) {
      // logout user on 401 error
      store.dispatch(logoutUser());
      if (typeof window !== "undefined") {
        window.location.assign = "/";
      }
    }
    apiErrorHandler(error);
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
