import Cookies from "js-cookie";
import { AxiosInstance } from "../utils/axios-helper/axios-helper";
import { API_END_POINT } from "../utils/constants/api-end-points";

export const registerUser = async (data) => {
  const reqData = {
    ...data,
    HfSession: Cookies?.get("__cds_pixel_id"),
  };
  return await AxiosInstance.post(API_END_POINT.registerUser, reqData);
};

export const getUserById = async (data) => {
  return await AxiosInstance.post(API_END_POINT.getUserById, data);
};

export const updateUserName = async (data) => {
  const reqData = {
    ...data,
    HfSession: Cookies?.get("__cds_pixel_id"),
  };
  return await AxiosInstance.post(API_END_POINT.updateUserName, reqData);
};

export const updateUserEmail = async (data) => {
  const reqData = {
    ...data,
    HfSession: Cookies?.get("__cds_pixel_id"),
  };
  return await AxiosInstance.post(API_END_POINT.updateUserEmail, reqData);
};

export const updateProfileImg = async (data) => {
  return await AxiosInstance.post(API_END_POINT.updateProfileImg, data);
};
