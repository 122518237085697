import React, { useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Navbar } from "react-bootstrap";

import NavLinks from "./nav-links";
import BuyNow from "./buy-now";
import OtherLinks from "./other-links";
import useAuth from "../../../utils/hooks/use-auth";
import usePopup from "../../../utils/hooks/use-popup";
import AdminNavLinks from "./admin-nav-links";

function SideNav() {
  const dispatch = useDispatch();
  const { isLoggedIn } = useAuth();
  const { openLoginPopup } = usePopup();
  const [isExpanded, setIsExpanded] = useState(false);
  const navbarRef = useRef();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isAdmin = pathname ==="/admin" || pathname === "/admin-leaderBoard" 
  const isPromoter = pathname === "/promoter"
  const toggleNavbar = useCallback(() => {
    setIsExpanded((p) => !p);
  }, []);

  const authNavigateTo = (route = "/") => {
    toggleNavbar();
    if (!isLoggedIn) {
      return openLoginPopup(true, route);
    }
    navigate(route);
  };
console.log(pathname,"pathname",isAdmin)
  const handleLogout = () => {
    window.dataLayer.push({ event: "lmslogout" });
    localStorage.clear();
    window.location.assign("/");
  };
console.log(!isPromoter,"isPromoter")
  return (
    <div className="sidebar-main">
      {
        !isPromoter &&
      <Navbar expand="xxl" expanded={isExpanded} ref={navbarRef}>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={toggleNavbar}
        />
        <Navbar.Collapse id="basic-navbar-nav" className="offcanvas-menu">
          <div className="sidebar-links">
            {
              !isAdmin ? (
                <><NavLinks
                  isLoggedIn={isLoggedIn}
                  toggleNavbar={toggleNavbar}
                  authNavigateTo={authNavigateTo} />

                  <OtherLinks toggleNavbar={toggleNavbar} isLoggedIn={isLoggedIn} /></>
              ) : (
                <AdminNavLinks toggleNavbar={toggleNavbar}/>
              )
            }
            {isLoggedIn && !isAdmin  && (
              <div className="devider mb-14">
                <Button
                  className="btn-primary"
                  size="sm"
                  onClick={handleLogout}
                >
                  LOGOUT
                </Button>
              </div>
            )}
          </div>
        </Navbar.Collapse>
      </Navbar>
      }
    </div>
  );
}

export default SideNav;
