import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { CURRENT_YEAR, PRIVACY_POLICY_URL, TERMS_AND_CONDITIONS_URL } from '../../utils/constants';
import { FLOODLIGHT_COUNTER, counterFloodLight } from '../../utils/helpers/floodlight-helper';
function Footer() {
  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="track">
          <img
            className="track-cone track-cone-left"
            src="/assets/img/icons/road-cone.svg"
            alt="Road cone"
          />
          <img
            className="track-runner track-runner-left"
            src="/assets/img/common/runner2.svg"
            alt="runner"
          />
          <img
            className="track-cone track-cone-right"
            src="/assets/img/icons/road-cone.svg"
            alt="Road cone"
          />
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container-fluid">
          <Row className="justify-content-between">
            {/* Footer Left Section */}
            <Col md={6} className="text-center text-md-left">
              <p>
                <sup className="special-char">&copy;</sup> {CURRENT_YEAR} The
                Coca-Cola Company. All Rights Reserved.
              </p>
              <p className="mb-0">
                <sup className="special-char special-char-reg">&reg; </sup>
                <span className="ml-sm-n1">
                  TRADEMARK OWNER: THE COCA-COLA COMPANY
                </span>
              </p>
            </Col>
            {/* Footer Right Section */}
            <Col md={6} className="text-center text-md-right mt-3 mt-md-0">
              <ul className="tnc-list">
                <li className="tnc-list-item"
                  onClick={() => {
                    counterFloodLight(FLOODLIGHT_COUNTER.LimcaSportz_ICC23_TC_Counter);
                  }}
                >
                  <Link to={TERMS_AND_CONDITIONS_URL} target={"_blank"} className="tnc-list-link">
                    *Terms and Conditions
                  </Link>
                </li>
                <li className="tnc-list-item" onClick={() => {
                  counterFloodLight(FLOODLIGHT_COUNTER.LimcaSportz_ICC23_PrivacyPolicy_Counter);
                }}
                >
                  <Link to={PRIVACY_POLICY_URL}
                    target={"_blank"} className="tnc-list-link">
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
