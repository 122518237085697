import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import useAuth from "../utils/hooks/use-auth";
import usePopup from "../utils/hooks/use-popup";
import OnGroundTestFrom from "../features/on-ground/on-ground-test-form";
import YoYoTestInstructions from "../features/yoyo-test/yoyo-test-instructions";
import { RESTRICTED_STATE, ELIGIBLE_COUNTRY } from "../utils/constants/common-constants";
import { useSelector } from "react-redux";

function OnGroundPage() {
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();
  const { openLoginPopup, openRestrictRegionPopup } = usePopup();
  const ipDetails = useSelector((state) => state.common.ipDetails);
  const restrictToRedirect = ipDetails && ipDetails.regionName === RESTRICTED_STATE ? true : false
  const isCountrynotIndia = ipDetails && ipDetails.country !== ELIGIBLE_COUNTRY


  useEffect(() => {
    if (restrictToRedirect || isCountrynotIndia) {
      openRestrictRegionPopup()
    } else if (!isLoggedIn) {
      openLoginPopup(true, "/on-ground");
    }
  }, [isLoggedIn, openLoginPopup]);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/", { replace: true });
    }
  }, [isLoggedIn, navigate]);



  return (
    <div className="container-fluid">
      <Row className="justify-content-center text-center">
        <Col xs={10} sm={9} md={8} className="mt-10 mt-sm-14 pt-sm-4">
          <h1>DRINK. SCAN. RUN</h1>
          <h4 className="text-primary">
            Scan the QR on a Limca&reg; Sportz bottle, take the #YoYoTest, and
            get a chance to win* ICC Men's Cricket World Cup 2023 Tickets!
          </h4>
        </Col>
      </Row>
      <Row className="justify-content-center text-center">
        <Col xs={12}>
          <img
            className="icon"
            src="/assets/img/common/shuttle.svg"
            alt="Ticket"
          />
        </Col>
        <Col xs={12} className="mt-8">
          <YoYoTestInstructions />
        </Col>
      </Row>
      <div className="d-flex justify-content-center mt-8">
        <OnGroundTestFrom route="employee"/>
      </div>
    </div>
  );
}

export default OnGroundPage;
