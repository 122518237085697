

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  // export const API_BASE_URL  = "https://limca.wahino.tk/"


export const GOOGLE_CAPTCHA_KEY = "6Ld21FsnAAAAABqVtZy_0_pRhrpFyZSrzO0MMcMk";

export const COUNTRY_CODE = "IN"; // global country code

export const BRAND_NAME = "Limca Sportz";
