import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "react-bootstrap";

import useLoader from "../../../utils/hooks/use-loader";
import useAuth from "../../../utils/hooks/use-auth";
import { getUserClaimTimeById } from "../../../services/fitness-tracker-service";
import useCountdown from "../../../utils/hooks/use-countdown";
import usePopup from "../../../utils/hooks/use-popup";
import { CLAIM_TYPE } from "../../../utils/constants/common-constants";
import { fetchUserById } from "../../../store/slices/auth-slice";
import { getUserClaimTime } from "../../../store/slices/common-slice";
import { FLOODLIGHT_COUNTER, counterFloodLight } from "../../../utils/helpers/floodlight-helper";
import { MetaPixel } from "../../../utils/helpers/metaHelper";

function FitnessTrackerWinning() {
  const dispatch = useDispatch();

  const claimTime = useSelector((state) => state.common.claimTime);
  console.log("claimTime: ", claimTime);
  const isTimeRemain = useSelector((state) => state.common.timeRemain);
  console.log("isTimeRemain: ", isTimeRemain);

  const { openUniqueCodePopup, openClaimPopup } = usePopup();
  const { userDetails } = useAuth();
  const { toggleLoader, isLoading } = useLoader();

  const [winTime, setWinTime] = useState("");
  console.log("winTime: ", winTime,userDetails);

  const timeRemaining = useCountdown(claimTime);

  const fetchClaimTime = useCallback(async () => {
    toggleLoader(true);
    try {
      const reqData = {
        Id: userDetails?.Id,
      };
      const res = await getUserClaimTimeById(reqData);
      if (res.status === 200) {
        const data = res.data?.Data;
        if (data) {
          const time = `${data.Date}T${data.Time}Z`;
          setWinTime(time);
        }
      }
    } catch (err) {
    } finally {
      toggleLoader(false);
    }
  }, [userDetails?.Id, toggleLoader]);

  useEffect(() => {
    if (!userDetails.IsFitbitClaim && !userDetails?.IsFitbitClaimTimeOver) {
      // only fetch time if not over and not claimed yet
      fetchClaimTime();
      dispatch(getUserClaimTime());
    }
  }, [
    fetchClaimTime,
    userDetails?.IsFitbitClaimTimeOver,
    userDetails.IsFitbitClaim,
    dispatch,
  ]);

  useEffect(() => {
    if (timeRemaining === "00:00:00") {
      setTimeout(() => {
        dispatch(fetchUserById());
      }, 2000);
    }
  }, [timeRemaining, dispatch]);

  const handleClaimFitnessTracker = () => {
    openClaimPopup(CLAIM_TYPE.FIT_BIT)(true);
    counterFloodLight(FLOODLIGHT_COUNTER.LimcaSportz_ICC23_ClaimNow_Counter);
  }
  const renderParticipate = () => {
    return (
      <Card className="bg-blur">
        <Card.Body>
          <Card.Title as="h3">Gear up!</Card.Title>
          <p className="text-capitalize">
            Grab your Limca® Sportz bottle and get a<br />
            chance to win a fitness tracker*
          </p>
          <img
            src="/assets/img/products/ft.svg"
            alt="Fitness Tracker"
            className="card-img card-img-fit"
          />
          <button
            className="btn btn-primary"
            onClick={() => {
              openUniqueCodePopup();
              counterFloodLight(FLOODLIGHT_COUNTER.LimcaSportz_ICC23_ParticipateNow_Counter);
              MetaPixel('ParticipateNow');
            }}
          >
            Participate Now
          </button>
        </Card.Body>
      </Card>
    );
  };

  const renderFailed = () => {
    return (
      <>
        <Card className="bg-blur">
          <Card.Body>
            <Card.Title as="h3">#RUKKMATT</Card.Title>
            <p className="text-capitalize">
              You missed this chance at winning, but many <br />
              more fitness trackers are still up for grabs! <br />
              Just try again with a new code.
            </p>
            <img
              src="/assets/img/products/ft.svg"
              alt="Fitness Tracker"
              className="card-img card-img-fit"
            />
            <button className="btn btn-primary" onClick={openUniqueCodePopup}>
              Enter New Code
            </button>
          </Card.Body>
        </Card>
      </>
    );
  };

  const renderSuccess = (canClaim = true) => {
    return (
      <Card className="bg-blur card-stars h-100">
        <Card.Body className="pt-8 pt-md-14">
          {canClaim && <Card.Subtitle>Congratulations</Card.Subtitle>}
          <Card.Title as="h3">
            You've won a Fitness
            <br />
            Tracker
          </Card.Title>
          {canClaim && <p>Claim now to avoid forfeiting</p>}
          <img
            src="/assets/img/products/ft.svg"
            alt="Fitness Tracker"
            className="card-img card-img-fit"
          />

          {canClaim && (
            <>
              <button
                className="btn btn-primary"
                // onClick={() => {
                //   openClaimPopup(CLAIM_TYPE.FIT_BIT);
                //   counterFloodLight(FLOODLIGHT_COUNTER.LimcaSportz_ICC23_ClaimNow_Counter);
                // }}
                onClick={() => handleClaimFitnessTracker()}
                style={{ zIndex: 8 }}
              >
                Claim your Fitness Tracker
              </button>
              <p className="small mt-4">Time Remaining: {timeRemaining}</p>
            </>
          )}
        </Card.Body>
      </Card>
    );
  };

  const renderTimeOut = () => {
    return (
      <Card className="bg-blur">
        <Card.Body>
          <Card.Title as="h3">Oops!</Card.Title>
          <p className="text-capitalize">
            Looks like you didn't claim your gear in time, but
            <br />
            many more fitness trackers are still up for grabs! <br />
            Just try again with a new code
          </p>
          <img
            src="/assets/img/products/ft.svg"
            alt="Fitness Tracker"
            className="card-img card-img-fit"
          />
          <button className="btn btn-primary" onClick={openUniqueCodePopup}>
            Enter Code
          </button>
        </Card.Body>
      </Card>
    );
  };

  if (isLoading) {
    return (
      <Card className="bg-blur">
        <Card.Body>
          <Card.Title as="h3">Loading..</Card.Title>
          <p>Please wait!</p>
        </Card.Body>
      </Card>
    );
  }

  if (userDetails?.IsFitnessTrackerWon && !userDetails?.IsFitbitClaimTimeOver) {
    return renderSuccess(!userDetails?.IsFitbitClaim);
  }

  if (userDetails?.IsFitnessTrackerWon && userDetails?.IsFitbitClaimTimeOver) {
    return renderTimeOut();
  }

  if (winTime) {
    if (timeRemaining) {
      return renderSuccess();
    } else {
      return renderTimeOut();
    }
  }

  return renderParticipate();
}

export default FitnessTrackerWinning;
