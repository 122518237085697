import React from 'react';
import { Card } from 'react-bootstrap';

function Shortlisted() {
  return (
    <>
      <Card.Title as="h3">Your Entry Has Been Shortlisted!</Card.Title>
      <p className="mb-0 text-capitalize">
        You are one step closer to winning
        <br />
        ICC Men's Cricket World Cup 2023 Tickets*!
      </p>
      <img
        src="/assets/img/products/ticket.png"
        alt="Ticket"
        className="my-5 my-sm-8"
      />
      <p className="mb-0 font-weight-bold">
        You will be contacted with details on next steps within 14 business
        days.
      </p>
    </>
  );
}

export default Shortlisted;
