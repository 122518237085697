import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

import AppModal from "../../components/modals";
import { Link } from "react-router-dom";
import { clickButtonCDP } from "../../utils/helpers/cds-helper";
import { FLOODLIGHT_COUNTER, counterFloodLight } from "../../utils/helpers/floodlight-helper";
import { MetaPixel } from "../../utils/helpers/metaHelper";

function HowToPlay({ show, onClose }) {
  const [open, setOpen] = useState(show);
  useEffect(() => {
    setOpen(show);
  }, [show]);

  const handleClose = () => {
    if (onClose && typeof onClose === "function") {
      onClose();
    }
    setOpen(false);
  };

  const handleOpen = () => {
    MetaPixel('HowtoPlay');
    setOpen(true);
    clickButtonCDP("HowToWin_FitnessTracker");
    counterFloodLight(FLOODLIGHT_COUNTER.LimcaSportz_ICC23_HowToPlay_Counter);
  };
  return (
    <>
      <Link className="link text-primary text-capitalize" onClick={handleOpen}>
        How to play
      </Link>
      <AppModal
        show={open}
        onClose={handleClose}
        dialogClassName="modal-xl"
        contentClassName="p-0 bg-transparent"
        headerClass="d-none"
        bodyClass="max-w-none px-0"
      >
        <button
          type="button"
          className="close mt-6 mr-n3"
          data-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        ></button>
        <img
          className="d-none d-md-block"
          src="/assets/img/common/howtoplay.png"
          alt="Ticket"
        />
        <img
          className="d-block d-md-none w-100"
          src="/assets/img/common/howtoplay-m.png"
          alt="Ticket"
        />
      </AppModal>
    </>
  );
}

export default HowToPlay;
