import * as yup from "yup";
import { FILE_SIZE } from "../constants/common-constants";
import { VALIDATION_MSG } from "../constants/validation-constants";
import { REGEX } from "../constants/validation-regex";

export const loginValidationSchema = yup.object({
  MobileNo: yup
    .string()
    .min(10, `${VALIDATION_MSG.valid["MobileNo"]}`)
    .max(10, `${VALIDATION_MSG.valid["MobileNo"]}`)
    .matches(REGEX.MobileNo, VALIDATION_MSG.valid["MobileNo"])
    .required(VALIDATION_MSG.required["MobileNo"]),
});

export const otpValidationSchema = yup.object({
  OTP: yup
    .string()
    .matches(REGEX.OTP, VALIDATION_MSG.valid["OTP"])
    .required(VALIDATION_MSG.required["OTP"]),
});

export const signUpValidationSchema = yup.object({
  ProfileImg: yup.string(),
  Email: yup
    .string()
    .test("valid-email-if-entered", VALIDATION_MSG.valid["Email"], (value) => {
      return value ? REGEX.Email.test(value) : true;
    }),
  AgreeTerms: yup
    .bool()
    .oneOf([true], "You must agree with the T&C and Privacy Policy"),
});

export const uniqueCodeValidationSchema = yup.object({
  Code: yup
    .string()
    .matches(REGEX.Code, VALIDATION_MSG.valid["Code"])
    .required(VALIDATION_MSG.required["Code"]),
});

export const claimFormValidationSchema = yup.object({
  Name: yup
    .string()
    .required(VALIDATION_MSG.required["Name"])
    .matches(REGEX.Name, VALIDATION_MSG.valid["Name"]),
  MobileNo: yup
    .string()
    // .matches(REGEX.MobileNo, VALIDATION_MSG.valid['MobileNo'])
    .required(VALIDATION_MSG.required["MobileNo"]),
  Email: yup
    .string()
    .required(VALIDATION_MSG.required["Email"])
    .email(VALIDATION_MSG.valid["Email"])
    .matches(REGEX.Email, VALIDATION_MSG.valid["Email"]),
  AddressLine1: yup.string().required(VALIDATION_MSG.required["AddressLine1"]),
  AddressLine2: yup.string(),
  State: yup.string().required(VALIDATION_MSG.required["State"]),
  City: yup.string().required(VALIDATION_MSG.required["City"]),
  PinCode: yup
    .string()
    .required(VALIDATION_MSG.required["PinCode"])
    .matches(REGEX.PinCode, VALIDATION_MSG.valid["PinCode"]),
});

export const mobileNoValidationSchema = yup.object({
  MobileNo: yup
    .string()
    .matches(REGEX.MobileNo, VALIDATION_MSG.valid["MobileNo"])
    .required(VALIDATION_MSG.required["MobileNo"]),
});

export const nameValidationSchema = yup.object({
  Name: yup
    .string()
    .required(VALIDATION_MSG.required["Name"])
    .matches(REGEX.Name, VALIDATION_MSG.valid["Name"]),
});

export const emailValidationSchema = yup.object({
  Email: yup
    .string()
    .email(VALIDATION_MSG.valid["Email"])
    .matches(REGEX.Email, VALIDATION_MSG.valid["Email"])
    .required(VALIDATION_MSG.required["Email"]),
});

export const yoyoTestFormValidationSchema = yup.object({
  video: yup.mixed(),
  video_upload: yup
    .mixed()
    .when("video", {
      is: (val) => val,
      then: (schema) => schema.notRequired(),
      otherwise: (schema) =>
        schema.required(VALIDATION_MSG.required["video_upload"]),
    })
    .test(
      "file_size",
      `Upload upto ${FILE_SIZE.VIDEO_UPLOAD_SIZE * 0.000001}MB only`,
      (value) => {
        return value && value?.[0]
          ? value[0]?.size <= FILE_SIZE.VIDEO_UPLOAD_SIZE
          : true;
      }
    )
    .test("file-type", VALIDATION_MSG.valid["Video_Type"], (value) => {
      return value && value?.[0] ? value[0]?.type?.search("video/") > -1 : true;
    }),
  Minutes: yup
    .string()
    .required(VALIDATION_MSG.required["Minutes"])
    .test(
      "minute-digit-length",
      ({ path }) => `Enter valid ${path}`,
      (value, testContext) => {
        console.log("value: ", value);
        if (value) {
          if (`${value}`.length !== 2) {
            return false;
          }
        }
        return true;
      }
    )
    .test(
      "max-minute",
      ({ path }) => `Enter ${path} between 1-60`,
      (value, testContext) => {
        console.log("value: ", value);
        if (value) {
          if (Number(value) > 60) {
            return false;
          }
          // else if (Number(value) <= 0) {
          //   return false;
          // }
        }
        return true;
      }
    ),
  Seconds: yup
    .string()
    .required(VALIDATION_MSG.required["Seconds"])
    .test(
      "second-digit-length",
      ({ path }) => `Enter valid ${path}`,
      (value, testContext) => {
        console.log("value: ", value);
        if (value) {
          if (`${value}`.length !== 2) {
            return false;
          }
        }
        return true;
      }
    )
    .test(
      "seconds-minute",
      ({ path }) => `Enter ${path} between 1-59`,
      (value, testContext) => {
        if (value) {
          if (Number(value) > 59) {
            return false;
          }
        }
        return true;
      }
    )
    .when("Minutes", {
      is: (val) => !val || Number(val) === 0,
      then: (schema) =>
        schema.test(
          "no-zero-sec",
          ({ path }) => `${path} also can't be Zero`,
          (value, testContext) => {
            if (value || value === "00") {
              if (Number(value) === 0) {
                return false;
              }
            }
            return true;
          }
        ),
    }),
  Level: yup.string().required(VALIDATION_MSG.required["Level"]),
  Shuttles: yup.string().required(VALIDATION_MSG.required["Shuttles"]),
});

const validateTotalTime = (minutes, seconds) => {
  const totalSeconds = Number(minutes) * 60 + Number(seconds);
  return totalSeconds >= 10;
};

export const yoyoOnGroundTestFormValidationSchema = yup.object({
  Name: yup.string().required(VALIDATION_MSG.required["Name"]),
  MobileNo: yup
    .string()
    .min(10, `${VALIDATION_MSG.valid["MobileNo"]}`)
    .max(10, `${VALIDATION_MSG.valid["MobileNo"]}`)
    .matches(REGEX.MobileNo, VALIDATION_MSG.valid["MobileNo"])
    .required(VALIDATION_MSG.required["MobileNo"]),
  // State: yup.string().required(VALIDATION_MSG.required["State"]),
  City: yup.string().required(VALIDATION_MSG.required["City"]),
  CreateDate: yup.string().required(VALIDATION_MSG.required["Date"]),
  Venue: yup.string().required(VALIDATION_MSG.required["Venue"]),
  Minutes: yup
    .string()
    .required(VALIDATION_MSG.required["Minutes"])
    .test(
      "minute-digit-length",
      ({ path }) => `Enter valid ${path}`,
      (value, testContext) => {
        console.log("value: ", value);
        if (value) {
          if (`${value}`.length !== 2) {
            return false;
          }
        }
        return true;
      }
    )
    .test(
      "max-minute",
      ({ path }) => `Enter ${path} between 1-60`,
      (value, testContext) => {
        console.log("value: ", value);
        if (value) {
          if (Number(value) > 60) {
            return false;
          }
          // else if (Number(value) <= 0) {
          //   return false;
          // }
        }
        return true;
      }
    ),
  Seconds: yup
    .string()
    .required(VALIDATION_MSG.required["Seconds"])
    .test(
      "second-digit-length",
      ({ path }) => `Enter valid ${path}`,
      (value, testContext) => {
        console.log("value: ", value);
        if (value) {
          if (`${value}`.length !== 2) {
            return false;
          }
        }
        return true;
      }
    )
    .test(
      "seconds-minute",
      ({ path }) => `Enter ${path} between 1-59`,
      (value, testContext) => {
        if (value) {
          if (Number(value) > 59) {
            return false;
          }
        }
        return true;
      }
    )
    .when("Minutes", {
      is: (val) => !val || Number(val) === 0,
      then: (schema) =>
        schema.test(
          "no-zero-sec",
          ({ path }) => `${path} also can't be Zero`,
          (value, testContext) => {
            if (value || value === "00") {
              if (Number(value) === 0) {
                return false;
              }
            }
            return true;
          }
        ),
    })
    .test("total-time", "Min Time must be 10 seconds", function (value) {
      const minutes = this.parent.Minutes;
      const seconds = value;
      return validateTotalTime(minutes, seconds);
    }),
  Level: yup.string().required(VALIDATION_MSG.required["Level"]),
  Shuttles: yup.string().required(VALIDATION_MSG.required["Shuttles"]),
});

export const adminLoginValidationSchema = yup.object({
  Email: yup
    .string()
    .email(VALIDATION_MSG.valid["Email"])
    .matches(REGEX.Email, VALIDATION_MSG.valid["Email"])
    .required(VALIDATION_MSG.required["Email"]),
  Password: yup.string().required(VALIDATION_MSG.required["Password"]),
});
