import Cookies from "js-cookie";
import { AxiosInstance } from "../utils/axios-helper/axios-helper";
import { API_END_POINT } from "../utils/constants/api-end-points";
import { store } from "../store";

export const sendOtp = async (data) => {
  return await AxiosInstance.post(API_END_POINT.sendOtp, data);
};

export const verifyOtp = async (data) => {
  const ipDetails = store.getState().common.ipDetails;
  console.log('Cookies.get("__cds_pixel_id"): ', Cookies.get("__cds_pixel_id"));

  const reqData = {
    ...data,
    HfSession: Cookies?.get("__cds_pixel_id"),
    StateCode: ipDetails?.region,
    City: ipDetails?.city,
    Pincode: ipDetails?.zip,
  };
  return await AxiosInstance.post(API_END_POINT.verifyOTP, reqData);
};
