import { Controller } from 'react-hook-form';
import Select from 'react-select';
import OtpInput from 'react-otp-input';

import SelectDropdownIndicator from '../components/common/select-dropdown-indicator';
import React from 'react';

export const TextInput = ({
  control,
  type = 'text',
  name,
  onInput,
  inputClass = '',
  inputProps = {},
  customMessage = '',
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field,
        fieldState: { invalid, isTouched, isDirty, error },
      }) => (
        <>
          <input
            type={type}
            className={`form-control ${
              error ? 'is-invalid' : ''
            } ${inputClass}`}
            {...inputProps}
            {...field}
            onInput={onInput}
          />
          {error && !customMessage && (
            <p className="invalid-feedback">{error.message}</p>
          )}
          {customMessage ? customMessage : null}
        </>
      )}
    />
  );
};

export const SelectInput = ({
  control,
  options = [],
  name,
  inputClass = '',
  inputProps = {},
  emptyLabel = 'Select',
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field,
        fieldState: { invalid, isTouched, isDirty, error },
      }) => (
        <>
          <select
            className={`form-select ${error ? 'is-invalid' : ''} ${inputClass}`}
            {...inputProps}
            {...field}>
            <option value="">{emptyLabel}</option>
            {options?.map((o) => (
              <option key={o.value} value={o.value}>
                {o.label}
              </option>
            ))}
          </select>
          {error && <div className="invalid-feedback">{error.message}</div>}
        </>
      )}
    />
  );
};

export const ReactSelectInput = ({
  control,
  options = [],
  name,
  inputClass = '',
  inputProps = {},
  isDisabled = false
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field,
        fieldState: { invalid, isTouched, isDirty, error },
      }) => (
        <>
          <Select
            components={{ DropdownIndicator: SelectDropdownIndicator }}
            options={options}
            classNamePrefix="form-select"
            className={`form-select-container ${
              error ? 'is-invalid' : ''
            } ${inputClass}`}
            {...inputProps}
            {...field}
            value={options.find((o) => o.value === field.value) || null}
            onChange={(e) => {
              field.onChange({
                target: {
                  value: e.value,
                },
              });
            }}
            isDisabled={isDisabled}
          />
          {error && <div className="invalid-feedback">{error.message}</div>}
        </>
      )}
    />
  );
};

export const UploadInput = ({
  name,
  inputClass = '',
  inputProps = {},
  register,
  error,
  customMessage = '',
}) => {
  return (
    <>
      <label className="btn-wrapper cursor-p m-0">
        <img
          className="btn-icon"
          src="/assets/img/icons/upload.svg"
          alt="record"
          onClick={() => {
            window.dataLayer.push({ event: 'lmsuploadsubmityoyotest' });
          }}
        />
        <span
          className="btn-text"
          onClick={() => {
            window.dataLayer.push({ event: 'lmsuploadsubmityoyotest' });
          }}>
          Upload
        </span>
        <input
          name={name}
          type="file"
          className={`btn-file  ${error ? 'is-invalid' : ''} ${inputClass}`}
          {...inputProps}
          {...register(name)}
        />
        {error && (
          <p className={`invalid-feedback ${error?.message ? 'd-block' : ''}`}>
            {error.message}
          </p>
        )}
        {customMessage && !error ? customMessage : null}
      </label>
    </>
  );
};

export const SeparateInput = React.forwardRef(
  (
    {
      control,
      name,
      inputClass = '',
      inputProps = {},
      customMessage = '',
      inputType = 'tel',
      readOnly = false,
      ...rest
    },
    ref
  ) => {
    return (
      <Controller
        name={name}
        control={control}
        render={({
          field,
          fieldState: { invalid, isTouched, isDirty, error },
        }) => (
          <>
            <OtpInput
              inputType={inputType}
              {...rest}
              {...field}
              ref={ref}
              inputStyle={{
                width: 52,
                margin: 'auto 5px',
              }}
              renderInput={(props) => (
                <input
                  type="text"
                  {...inputProps}
                  {...props}
                  className={`form-control ${
                    error ? 'border-danger' : ''
                  } ${inputClass}`}
                  aria-label={field.name}
                  readOnly={readOnly}
                />
              )}
            />

            {error && !customMessage && (
              <p className={`invalid-feedback ${error ? 'd-block' : ''}`}>
                {error.message}
              </p>
            )}
            {customMessage ? customMessage : null}
          </>
        )}
      />
    );
  }
);
