import { useContext } from "react";
import { PopupContext } from "../context/popup-context";

function usePopup() {
  const {
    openLoginPopup,
    openUniqueCodePopup,
    openClaimPopup,
    openGenerateARCertificatePopup,
    openRestrictRegionPopup
  } = useContext(PopupContext);

  return {
    openLoginPopup,
    openUniqueCodePopup,
    openClaimPopup,
    openGenerateARCertificatePopup,
    openRestrictRegionPopup
  };
}

export default usePopup;
