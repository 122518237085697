/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Alert, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";

import usePopup from "../../../utils/hooks/use-popup";
import { FLOODLIGHT_COUNTER, counterFloodLight } from "../../../utils/helpers/floodlight-helper";
import { useSelector } from "react-redux";
import { RESTRICTED_STATE,ELIGIBLE_COUNTRY } from "../../../utils/constants/common-constants";

function NavLinks({ isLoggedIn, toggleNavbar, authNavigateTo }) {
  const { openLoginPopup, openUniqueCodePopup,openRestrictRegionPopup} = usePopup();
  const ipDetails = useSelector((state) => state.common.ipDetails);
  const restrictToRedirect = ipDetails && ipDetails.regionName === RESTRICTED_STATE ? true : false
  const isCountrynotIndia = ipDetails && ipDetails.country !== ELIGIBLE_COUNTRY


  const handleLogin = () =>{
    if (restrictToRedirect ||isCountrynotIndia ) {
      openRestrictRegionPopup()
    }else{
      openLoginPopup()
    }
  }

  const handleUniqueCode = () =>{
    if (restrictToRedirect || isCountrynotIndia) {
      openRestrictRegionPopup()
    }else{
      openUniqueCodePopup()
    }
  }

  const handleAutoNavigate = () =>{
    if(restrictToRedirect || isCountrynotIndia){
      openRestrictRegionPopup()
    }else{
      authNavigateTo("/yoyo")
    }
  }
  return (
    <>
      <ul className="main-nav">
        <li className="main-nav-item" onClick={toggleNavbar}>
          <Link to="/" className="main-nav-link">
            <img
              className="main-nav-icon"
              src="/assets/img/icons/home.svg"
              alt="Home"
            />
            <span>Home</span>
          </Link>
        </li>
        {!isLoggedIn && (
          <li className="main-nav-item" onClick={toggleNavbar}>
            <Link to="/" className="main-nav-link" onClick={handleLogin}>
              <img
                className="main-nav-icon"
                src="/assets/img/icons/login.svg"
                alt="Login"
              />
              <span>Login</span>
            </Link>
          </li>
        )}
        {isLoggedIn && (
          <li className="main-nav-item" onClick={toggleNavbar}>
            <Link to="/my-winnings" className="main-nav-link">
              <img
                className="main-nav-icon"
                src="/assets/img/icons/win.svg"
                alt="Win trophy"
              />
              <span>My Winnings</span>
            </Link>
          </li>
        )}
        <li
          className="main-nav-item"
          onClick={() => {
            toggleNavbar();
            handleUniqueCode();
          }}
        >
          <Link to="/" className="main-nav-link"
            onClick={() => {
              counterFloodLight(FLOODLIGHT_COUNTER.LimcaSportz_ICC23_FitnessTracker_Counter);
            }}
          >
            <img
              className="main-nav-icon"
              src="/assets/img/icons/fitbit.svg"
              alt="Fitness tracker"
            />
            <span>Fitness Tracker Promotion</span>
          </Link>
        </li>
        <li className="main-nav-item" onClick={() => handleAutoNavigate()}>
          <Link to="/" className="main-nav-link"
            onClick={() => {
              counterFloodLight(FLOODLIGHT_COUNTER.LimcaSportz_ICC23_YoYoTest_Counter);
            }}
          >
            <img
              className="main-nav-icon"
              src="/assets/img/icons/yo-yo.svg"
              alt="Yo yo test"
            />
            <span>Yo-Yo Test</span>
          </Link>
        </li>
      </ul>
    </>
  );
}

export default NavLinks;
