import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

import YoYoTestForm from "../features/yoyo-test/yoyo-test-form";
import YoYoTestInstructions from "../features/yoyo-test/yoyo-test-instructions";
import YoYoEntrySuccess from "../features/yoyo-test/yoyo-entry-success";
import useAuth from "../utils/hooks/use-auth";
import { fetchUserById } from "../store/slices/auth-slice";
import { clickButtonCDP } from "../utils/helpers/cds-helper";
import { FLOODLIGHT_COUNTER, counterFloodLight } from "../utils/helpers/floodlight-helper";

function YoyoPage() {
  const dispatch = useDispatch();
  const { isLoggedIn, userDetails } = useAuth();
  const navigate = useNavigate();

  const [openSuccess, setOpenSuccess] = useState(false);

  useEffect(() => {
    clickButtonCDP("Take_Yo_Yo_Test");

  }, []);

  useEffect(() => {
    dispatch(fetchUserById());
  }, [dispatch]);

  const handleSuccess = () => {
    setOpenSuccess(true);
  };

  if (!isLoggedIn) {
    return <Navigate to="/" replace />;
  } else if (userDetails?.IsShortlisted || userDetails?.IsYoYoTicketClaim) {
    return <Navigate to="/my-winnings" replace />;
  }

  return (
    <>
      <div className="container-fluid">
        <Row className="justify-content-center text-center">
          <Col xs={10} sm={9} md={8} className="mt-10 mt-sm-14 pt-sm-4">
            <h1>DRINK. SCAN. RUN</h1>
            <h4 className="text-primary">
              Scan the QR on a Limca&reg; Sportz bottle, take the #YoYoTest, and
              get a chance to win* ICC Men's Cricket World Cup 2023 Tickets!
            </h4>
          </Col>
        </Row>
        <Row className="justify-content-center text-center">
          <Col xs={12}>
            <img
              className="icon"
              src="/assets/img/common/shuttle.svg"
              alt="Ticket"
            />
          </Col>
          <Col xs={12} className="mt-8">
            <YoYoTestInstructions />
          </Col>
        </Row>
        <div className="d-flex justify-content-center mt-8">
          <YoYoTestForm onSuccess={handleSuccess} />
        </div>
      </div>

      <YoYoEntrySuccess
        show={openSuccess}
        onClose={() => {
          navigate("/my-winnings");
          setOpenSuccess(false);
        }}
      />
    </>
  );
}

export default YoyoPage;
