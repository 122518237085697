import React from "react";
import AppModal from "./index";

const ErrorModel = ({show, onClose, subHeading}) => {
    return (
        <AppModal 
            show={show} 
            onClose={onClose} 
            heading="Sorry!"
            subHeading={subHeading}
        >
           
        </AppModal>
    )
}

export default ErrorModel